@mixin font-smoothing {
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	font-smoothing: antialiased;
	font-smooth: antialiased;
	-webkit-text-rendering: optimizeLegibility;
	-moz-text-rendering: optimizeLegibility;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	text-size-adjust: none;
}

// truncates a text
// Requires inline-block or block for proper styling
@mixin text-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
}

// automatic hyphenation
@mixin hyphens($mode: auto) {
	word-wrap: break-word;
	-webkit-hyphens: $mode;
					hyphens: $mode;
}

@mixin sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

@mixin sr-only-focusable {
	@include sr-only;
	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}
