.hamburger {
	text-indent: -250px;
	overflow: hidden;
	$hamburger: &;
	padding: 0.25rem;
	transition: 0.2s $ease-out-quad;
	color: currentColor;
	z-index: 10;
	background: none;
	outline: none;
	cursor: pointer;

	&__inner {
		position: relative;
		display: block;
		width: 1.2rem;
		height: 1.2rem;
	}

	&__line {
		position: absolute;
		width: 100%;
		height: 0.085rem;
		display: block;
		background: currentColor;
		border-radius: 0.25rem;
		transition: 0.2s $ease-out-quad;

		&:nth-child(1) {
			top: 0.1em;
			right: 0;
		}

		&:nth-child(2) {
			top: 50%;
			right: 0;
			transform: translate3d(0, -50%, 0);
		}

		&:nth-child(3) {
			bottom: 0.1em;
			right: 0;
		}

		.show--nav & {
			&:nth-child(1) {
				top: 50%;
				transform: translate3d(0, -50%, 0) rotate(-45deg);
			}

			&:nth-child(2) {
				opacity: 0;
				transform: translate3d(100%, -50%, 0);
			}

			&:nth-child(3) {
				bottom: 50%;
				transform: translate3d(0, 50%, 0) rotate(45deg);
			}
		}
	}

	.page--scrolled-fullheight & {
		color: $color-white;
	}
}


