body {
	display: flex;
	flex-flow: column;

	.show--nav & {
		overflow: hidden;
	}
}

.content {
	flex: 1 1 auto;
}

.footer {
	flex: 0 0 auto;
}

//renamed old .block class
.module {
	@include module-padding();

}

.container {
	@include container('normal');

	&--slim {
		@include container('slim');
	}

	&--narrow {
		@include container('narrow');
	}

	&--medium {
		@include container('medium');
	}

	&--fluid {
		@include container('fluid');
	}

	&--wide {
		@include container('wide');
	}

	&,
	&--slim,
	&--narrow,
	&--medium,
	&--fluid {
		.container {
			&,
			&--slim,
			&--narrow,
			&--medium,
			&--fluid {
				@include responsify($spacing-no, 'padding-right');
				@include responsify($spacing-no, 'padding-left');
			}
		}
	}
}

.mod_article {
	&.block {
		.startpage & {
			padding-top: 0;
		}
	}
}

.ce_image {
	&.block {
		@include module-padding($top: false, $bottom: false);
	}
}

.ce_headline {
	@include responsify($wrapper-padding, padding-left);
	@include responsify($wrapper-padding, padding-right);
	margin-bottom: 0;
}

.ce_text {
	@include responsify($wrapper-padding, padding-left);
	@include responsify($wrapper-padding, padding-right);
}
