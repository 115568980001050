.nav-wrapper {
	@include media($breakpoint-desktop-down) {
		display: none;
		background: $color-highlight;
		position: fixed;
		inset: 0;
	}

	.show--nav & {
		display: block;
		animation: nav-wrapper-fade-in 0.2s ease forwards;
		z-index: 99;
	}

	.mod_navigation {
		&.block {
			@include responsify($spacing-no, padding);
		}
	}

	.nav-main {
		// main navigation
		@include media($breakpoint-desktop-down) {
			@include container('wide');
			@include responsify(last($font-sizes-lg));
			@include responsify(last($line-height-xl), line-height);
			position: absolute;
			top: 30%;
			transform: translateY(-30%);
		}

		&__nav {
			$nav: '.nav-main__nav';
			@include responsify($font-sizes-nav);
			@include responsify($spacing-xl, column-gap);
			@include reset-list();
			display: flex;
			flex-flow: column;
			font-weight: $font-weight-bold;

			@include media($breakpoint-desktop-up) {
				flex-flow: row;
			}

			/* hover behavior */
			&:hover {
				#{$nav}-item {
					&:not(:hover) {
						#{$nav}-item-link {
							opacity: 0.5;
							background-clip: text;

							.page--scrolled-fullheight & {
								@include media('>=lg') {
									opacity: 1;
									background: $color-grey-nav;
									background-clip: text;
								}
							}
						}
					}
				}
			}

			&:hover {
				#{$nav}-item {
					&:hover {
						#{$nav}-item-link {
							opacity: 1;

							.page--scrolled-fullheight & {
								@include media('>=lg') {
									background: $color-white;
									background-clip: text;
								}
							}
						}
					}
				}
			}

			/* active behavior */
			#{$nav}-item-link {
				-webkit-background-clip: text;

				&.active {
					text-decoration: underline;
				}
			}

			@supports selector(:has(*)) {
				&:has(.active) {
					#{$nav}-item-link {
						opacity: 0.5;
						background-clip: text;

						.page--scrolled-fullheight & {
							@include media('>=lg') {
								opacity: 1;
								background: $color-grey-nav;
								background-clip: text;
							}
						}
					}
				}

				#{$nav}-item-link {
					&.active {
						opacity: 1;
						text-decoration: none;
						background-clip: text;

						.page--scrolled-fullheight & {
							@include media('>=lg') {
								background: $color-white;
								background-clip: text;
							}
						}
					}
				}
			}

			&-item {
				&-link {
					@include reset-link();
					transition: opacity 0.25s ease 0.15s, background-color 0.25s ease 0s;
				}
			}
		}
	}
}

.mobile-navigation {
	color: $mobile-navi-color;

	@include media($breakpoint-desktop-up) {
		display: none;
	}

	.show--nav & {
		color: $color-white;
		z-index: 100;
	}

	.header--transparent & {
		color: $color-white
	}
}

.navigation-footer {
	// footer navigation
	.mod_navigation {
		&.block {
			@include responsify($spacing-no, padding);
		}
	}

	.nav-main {
		&__nav {
			$nav: '.nav-main__nav';
			@include responsify($spacing-xl, column-gap);
			@include reset-list();
			display: flex;
			flex-flow: row wrap;
			row-gap: 0.8em;

			@include media('>=lg') {
				row-gap: 0;
			}

			&:hover {
				#{$nav}-item {
					&:not(:hover) {
						#{$nav}-item-link {
							opacity: 0.5;
							color: currentColor;
						}
					}
				}
			}

			&:hover {
				#{$nav}-item {
					&:hover {
						#{$nav}-item-link {
							transition: opacity 0.25s ease;
							opacity: 1;
							color: $color-highlight;
						}
					}
				}
			}

			&:has(.active) {
				#{$nav}-item-link {
					opacity: 0.5;
					color: currentColor;
				}

				#{$nav}-item-link {
					&.active {
						opacity: 1;
						color: $color-highlight;
					}
				}
			}

			&-item {
				&-link {
					@include reset-link();
					transition: opacity 0.25s ease;
				}

				&-text {
					transition: color 0.25s ease;
					font-weight: $font-weight-bold;
				}
			}
		}
	}
}

.footer-navi-ref {
	// footer navigation copy in mobile menu
	@include responsify($spacing-lg, padding-bottom);
	@include container('wide');
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	justify-content: space-between;
	align-items: center;
	z-index: 99;

	.show--nav & {
		display: flex;
		flex-flow: column;
		justify-content: flex-start;
		row-gap: 0.8rem;
	}

	.navigation-footer,
	.social-menu {
		width: 100%;
	}

	.social-menu {
		@include responsify($spacing-sm, column-gap);
	}

	.navigation-footer {
		.nav-main {
			&__nav {
				$nav: '.nav-main__nav';

				&:has(.active) {
					#{$nav}-item-link {
						color: $color-white;
					}
				}

				&:hover {
					#{$nav}-item {
						&:hover {
							#{$nav}-item-link {
								color: currentColor;
							}
						}
					}
				}
			}
		}
	}
}

@keyframes nav-wrapper-fade-in {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
