@mixin full-height-media($fit: cover, $position: center) {
	object-fit: $fit;
	object-position: $position;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&::-webkit-media-controls {
		display: none;
	}

	@content;

}
