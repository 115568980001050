.animate-letters {
    .word {
        overflow: hidden;
        display: inline-block;
        margin-right: 0.25em;
        padding-bottom: 0.13em;
        margin-bottom: -0.25em;
        white-space: nowrap;

			@include media('<sm') {
				line-height: 0.6;
			}
    }

    .character {
      display: inline-block;
      will-change: transform;
      transform: translateY(calc(100% + 0.28em));
      transition: transform 0.6s cubic-bezier(0, 0.54, 0, 0.98);
      line-height: 0.9;
    }

    .page--ready & {
       & {
        span:not(.word) {
          transform: translateY(0);
          padding-top: 0.12em
        }
      }
    }
}
