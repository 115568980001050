// THIS FILE MAPS THE BASIC CLASSES AND SEMANTICS OF THIS BOILERPLATE TO THE PROJECTS CLASSES OR SEMANTICS - YOU HAVE TO ADJUST THIS FILE ACCORDING YOUR NEEDS
// debug

@-ms-viewport {
	width: device-width;
}

* {
	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

html {
	box-sizing: border-box;
	font-size: 16px;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
	min-height: 100%;
	display: flex;
	//height: -webkit-fill-available;
}

body {
	flex: 1;
	//min-height: -webkit-fill-available;
}

// Basic FONT  usage
body,
input,
select,
td,
textarea,
th,
textarea,
input,
select,
button {
	@include fluidsize($font-sizes-nm);
	@include responsify($line-height-lg, 'line-height');
	@include font-smoothing;
	@include font('normal');
	font-variant-ligatures: no-common-ligatures;
}

body {
	color: $text-color;
	background: $body-bg;
	overflow-y: auto;
	overflow-x: hidden;
	-ms-overflow-style: scrollbar;
	-webkit-overflow-scrolling: touch;

	&.dark,
	&.theme--motion {
		background: $color-coal;
	}
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-weight: $font-weight-bold;
	margin: 0 0 0.375em;
}

h1, .h1 {
	@include fluidsize($font-sizes-xxxl);
	@include responsify($line-height-sm, 'line-height');
	letter-spacing: -0.02em;
}

.h1--fluid-stepped {
	@include responsify-fluid($font-sizes-xxxl);
}

h2, .h2 {
	@include fluidsize($font-sizes-xxl);
	@include responsify($line-height-nm, 'line-height');
	letter-spacing: -0.02em;
}

.h2--fluid-stepped {
	@include responsify-fluid($font-sizes-xxl);
}

h3, .h3 {
	@include fluidsize($font-sizes-xl);
	letter-spacing: -0.02em;
}

.h3--fluid-stepped {
	@include responsify-fluid($font-sizes-xl);
}

h4, .h4 {
	@include fluidsize($font-sizes-lg);
}

.h4--fluid-stepped {
	@include responsify-fluid($font-sizes-lg);
}

h5, .h5 {
	@include fluidsize($font-sizes-md);
}

.h5--fluid-stepped {
	@include responsify-fluid($font-sizes-md);
}

h6, .h6 {
	@include fluidsize($font-sizes-sm);
}

.h6--fluid-stepped {
	@include responsify-fluid($font-sizes-sm);
}

a,
.link {
  @include linkHover();
  color: $link-color;
  text-decoration: $link-decoration;
  transition: $link-transition;
  transition-property: background-size;

	@include hover() {
		&:hover {
			color: $link-hover-color;
			text-decoration: $link-hover-decoration;
		}
	}

	&:focus-visible {
		@include focusStyle();
	}
}

p {
	@include fluidsize($font-sizes-nm);
  break-inside: avoid;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

small {
	font-size: 0.75em;
}

hr {
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 1.5rem 0;
}

address {
	font-style: inherit;
}

b,
strong,
.strong {
	font-weight: $font-weight-bold;
}

em {
	font-style: italic;
}

img	{
	max-width: 100%;
	height: auto;
	display: block;
}

svg {
	max-width: 100%;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

blockquote {
  margin: 0 0 1.5rem;
}

[role="button"] {
  cursor: pointer;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

textarea {
	resize: vertical;
}

[hidden] {
  display: none !important;
}

.invisible {
	display: none;
}

figure {
	margin: 0;
}

.overlay-active {
	header {
		z-index: 9999;
	}

	body {
		overflow: hidden;
	}
}

.highlight-text {
	color: $text-color-highlight;
}


::selection {
    color: $color-white;
    background: $color-interactive;
}
