.cookie-banner {
	&__container {
		position: fixed;
		bottom: 1rem;
		left: 1rem;
		right: 1rem;
		opacity: 0;
		transform: translateY(20px);
		transition: opacity 0.3s ease 2s, transform 0.3s ease 2s;
		z-index: 99999999;

		.page--ready & {
			opacity: 1;
			transform: translateY(0);
		}

		.cookie-consent--done & {
			display: none;
		}
	}

	&__content {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		background: $color-dark-coal;
		color: $color-white;
		padding: 1.75em;

		@include media('>=md') {
			flex-flow: row;
		}

		&-right {
			display: flex;
			align-content: center;
			align-items: center;

			@include media('>=md') {
				justify-content: center;
			}
		}

		&-left {
			flex: 1;
		}
	}

	&__buttons {
		display: flex;
		flex-flow: row wrap;
		width: auto;
		margin-top: 1em;

		@include media('>=md') {
			flex-flow: row;
			margin-top: 0;
			margin-bottom: 0;
		}

		a.link,
		a.primary-link {
			display: block;
			margin-right: 50%;
			margin-top: 1rem;

			@include media('>=md') {
				display: inline-block;
				margin-right: 0;
				margin-top: 0;
				margin-left: 2em;
			}
		}

	}
}

.dialog {
	display: none;
	flex-flow: column;
	align-items: flex-start;
	width: 100%;
	margin: auto;
	max-width: 768px;
	height: 100vh;
	max-height: none;
	padding: 2.5em;
	border: none;
	z-index: 999999999;
	background: $color-white;
	color: $color-dark-coal;

	@include media('>=md') {
		height: fit-content;
		max-height: 768px;
	}

	&:focus-visible {
		outline: none;
	}

	&[open] {
		display: flex;
	}

	&::backdrop {
		content: '';
		position: fixed;
		inset: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.55);
		transition: background ease 0.5s;
		z-index: -1;
	}

	&__close {
		@include reset-button();
		align-self: flex-end;
		color: inherit;
		padding: 0.25em;
		width: 2.5em;
		height: 2.5em;

		&:hover {
			svg {
				scale: 1.1;
			}
		}

		svg {
			display: block;
			width: 100%;
			height: 100%;
			transition: 0.3s $ease-custom;
		}
	}

	.form-checkbox {
		&__field[disabled] {
			+.form-checkbox__label {
				.form-checkbox__label-box {
					cursor: not-allowed;
					opacity: 0.5;
				}
			}
		}

		&__label-box {
			top: 0;
		}

		&__label-text {
			font-size: 1.47em;
			color: $color-highlight;
		}
	}
}
