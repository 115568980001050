.scroll-to-top {
  @include reset-button();
  background: $color-white;
  border-radius: 2rem;
  box-shadow: 0 0 1rem rgba($color-black, 0.3), 0 0 2rem rgba($color-black, 0.1);
	cursor: n-resize;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 2rem;
  margin: 2rem auto;
  opacity: 0;
	overflow: hidden;
  position: fixed;
  position: sticky;
  bottom: 2rem;
  transform: translate3d(0, 200%, 0);
  transition: 0.4s $ease-custom;
  width: 3rem;
  height: 3rem;

  .page--scrolled-deep & {
    opacity: 1;
    transform: none;
  }

  @include hover {
    &:hover {
      box-shadow: 0 0 1rem rgba($color-black, 0.3), 0 0 3rem rgba($color-black, 0.3);

      &:before {
        animation: scroll-to-top-icon 0.3s linear;
        color: $color-interactive;
      }
    }
  }

  &:before {
    content: '↑';
    transition: color 0.3s $ease-out-quad;
  }
}

@keyframes scroll-to-top-icon {
  0% {
    transform: none;
  }

  50% {
    transform: translate3d(0, -200%, 0);
  }

  51% {
    transform: translate3d(0, 200%, 0);
  }

  100% {
    transform: none;
  }
}
