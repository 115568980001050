.demo {
	&__container {
		&-margin {
			@include responsify($spacing-sm, 'margin-bottom');
			background: #EDCE9E; // this color should match the color for margins from chrome inspector
		}

		&-highlighted {
			background: #CCDFB7; // this color should match the color for paddings from chrome inspector
		}

		&-inner {
			background: #AEC3E8; // this color should match the color for the element from chrome inspector
		}
	}

	&__text {
		@include responsify($font-sizes-sm);
		@include responsify($line-height-lg, 'line-height');

		&--fluid {
			@include fluidsize($font-sizes-sm);
			@include fluidsize($line-height-lg, 'line-height');
		}
	}
}

.font-style {
	&--normal {
		@include font('normal');
	}

	&--medium {
		@include font('medium');
	}

	&--bold {
		@include font('bold');
	}

	&--italic {
		@include font('italic');
	}
}

.grid-container {
	@include grid($cols: 2, $col-gap: $spacing-sm, $row-gap: $spacing-sm, $auto-flow: row dense);
	color: $color-white;

	@include media('>=md') {
		@include grid-temp-col($cols: 3);
	}

	span {
		@include grid-item(1, 1);
		@include responsify($spacing-md, padding);
		background: $color-grey-light;
		color: $color-black;
		min-height: 200px;

		&.grid-test {
			@include grid-item(2, 1);
		}
	}
}

// TODO: change radio and checkbox inputs to contao form generator style and remove these styles
.radio-container {
	@include responsify($spacing-md, 'margin-bottom');

 .check-box {
	 &--before {
		 @include radio-container('before');
	 }

	 &--after {
		 @include radio-container('after');
	 }

	 input {
		 @include radio();
	 }

	 label {
		 @include radio-label();
	 }
 }
}
.checkbox-container {
	@include responsify($spacing-md, 'margin-bottom');

 .check-box {
	 &--before {
		 @include radio-container('before', $border-radius: 0px);
	 }

	 &--after {
		 @include radio-container('after', $border-radius: 0px);
	 }

	 input {
		 @include radio();
	 }

	 label {
		 @include radio-label();
	 }
 }
}

.parallax-test {
  overflow: hidden;
  position: relative;
	height: 50vh;

  .media-container {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0,0,0);
    width: 100%;
    height: 150%;
  }
}

.parallax-full-test {
  position: relative;
	height: 300vh;

  .media-container {
    padding: 0;
    position: sticky;
    top: 0;
    height: 100vh;

		img {
			transform: translate3d(0,0,0);
			width: 300%;
			max-width: none;
		}
  }
}

