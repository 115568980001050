.footer {
	position: relative;
	@include responsify($spacing-xxl, 'padding-top');
	@include responsify($spacing-xxl, 'padding-bottom');
	background: $footer-background-color;
	color: $color-white;

	&__link {
		@include fluidsize($font-sizes-sm);
	}

	&__content {
		@include responsify($wrapper-padding, padding-left);
		@include responsify($wrapper-padding, padding-right);
		@include responsify($spacing-xl, row-gap);
		display: flex;
		justify-content: space-between;
		flex-flow: column;

		@include media($breakpoint-desktop-up) {
			@include responsify($spacing-no, row-gap);
			flex-flow: row;
		}
	}
}

.social-menu {
	@include responsify($spacing-sm, column-gap);
	display: flex;

	&__link {
		@include reset-link();
		display: flex;
		align-items: center;
		overflow: visible;
		color: $color-white;
		transition: transform 0.3s ease;

		&:hover {
			transform: scale(1.08);
		}

		svg {
			width: 2em;
			height: 2em;
		}
	}
}
