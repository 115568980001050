.media-reveal {
	$media: &;
	display: block;
	margin: 0;
	padding: 0;
	position: relative;
	transition-delay: $effect-delay;

	&:after {
		background: $media-reveal-color;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transform-origin: right top;
		transform: scale(1);
	}

	.no-js &,
	.page--ready &--revealed {
		&:after {
			transform: scaleX(0);
			transition: transform 0.5s $ease-custom-reveal;
			transition-delay: inherit;
		}
	}

	> img,
	> video {
		display: block;
		width: 100%;
		height: auto;
	}
}

