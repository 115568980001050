.icons-grid {
	@include bgColors();
	color: $color-white;
	background: $color-coal;

	&__wrapper {
		@include responsify($wrapper-padding, 'padding-left');
		@include responsify($wrapper-padding, 'padding-right');
	}

	&__top-content {
		@include responsify($spacing-xxxl, margin-bottom);
		text-align: center;
	}

	&__lead-text {
		@include fluidsize($font-sizes-xxl);
		font-weight: $font-weight-bold;
		line-height: 1.1;
	}

	&__sub-text {
		@include responsify($spacing-sm, margin-top);
	}

	&__content {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		height: 100%;
		row-gap: 2em;

		@include media('>=ms') {
			column-gap: 5em;
			row-gap: 3em;
		}

		@include media('>=xl') {
			column-gap: 0;
		}
	}

	&__single {
		display: flex;
		flex: 0 0 50%;
		max-width: 50%;
		flex-flow: column;
		align-items: center;
		justify-content: center;

		@include media('<xs') {
			justify-self: flex-start;

			&:nth-child(2n + 2) {
				justify-self: flex-end;
			}
		}

		@include media($breakpoint-desktop-up) {
			flex: 0 0 25%;
			max-width: 25%;

			&:nth-child(4n + 1) {
				//justify-self: flex-start;
			}

			&:nth-child(4n + 4) {
				//justify-self: flex-end;
			}
		}


		a {
			@include reset-link();
			display: block;
		}

		img {
			display: inline;
			height: auto;

			@include media($breakpoint-desktop-down) {
				max-width: 120px;
			}

			@include media('>=xl') {
				max-width: 200px;
			}
		}
	}

	&__small-text {
		@include responsify($spacing-sm, margin-top);
		text-align: center;
		max-width: 90%;
	}
}
