.flexible-element {
	$flex-el: &;
	color: $color-primary;

	&__content {
		@include bgColors();
		display: flex;
		flex-flow: column;
		justify-content: center;

		@include media($breakpoint-desktop-up) {
			flex-flow: row;
			align-items: stretch;
		}

		#{$flex-el}--txtcols & {
			@include media($breakpoint-desktop-up) {
				flex-flow: row wrap;
			}
		}
	}

	&__title {
		#{$flex-el}--txtcols & {
			flex: 0 0 100%;
			margin-bottom: 1em;
			@include responsify($wrapper-padding, padding-top);
			@include responsify($wrapper-padding, padding-left);
			@include responsify($wrapper-padding, padding-right);
		}
	}

	&__image,
	&__wrapper {
		flex: 0 0 100%;
		max-width: 100%;

		@include media($breakpoint-desktop-up) {
			flex: 0 0 50%;
			max-width: 50%;
		}

		#{$flex-el}--text-only & {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	&__image {
		$image-el: &;

		&-wrapper {
			position: relative;
			display: block;
			width: 100%;
			margin: 0;
			aspect-ratio: 1/1;

			@include media($breakpoint-desktop-down) {
				order: 0;
			}

			/* handle proportional image sizes and displaying */
			&--proportional {
				aspect-ratio: auto;

				#{$image-el}-element {
					object-fit: contain;
				}
			}
		}

		&-element {
			@include full-height-media;
		}
	}

	&__video {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	&__wrapper {
		@include responsify($wrapper-padding, padding);
		position: relative;
		align-self: center;
		width: 100%;
		height: 100%;
		margin: auto;

		@include media($breakpoint-desktop-down) {
			order: 1;
		}

		#{$flex-el}--txtcols & {
			padding: 0;
			margin: 0;
			@include responsify($wrapper-padding, padding-bottom);
			@include responsify($wrapper-padding, padding-left);
			@include responsify($wrapper-padding, padding-right);
		}

		#{$flex-el}--txtcols-notitle & {
			@include responsify($wrapper-padding, padding-top);
		}
	}

	&__text {
		margin: 0 auto;

		&-title {
			@include responsify($spacing-sm, margin-bottom);
			font-weight: $font-weight-bold;
		}

		#{$flex-el}--text-only & {
			position: static;
			transform: none;
			margin: 0;

			@include media($breakpoint-desktop-up) {
				@include responsify($spacing-no, 'padding-right');
				max-width: 960px;
			}
		}

		#{$flex-el}--text-centered & {
			@include media($breakpoint-desktop-up) {
				text-align: center;
				margin: 0 auto;
			}
		}

		&-text {
			color: currentColor;

			p:last-child {
				margin-bottom: 0;
			}
		}

		&-link {
			&--centered {
				display: flex;
				justify-content: flex-start;

				@include media($breakpoint-desktop-up) {
					justify-content: center;
				}
			}
		}
	}
}

.mod_showcase_reader {
	.hero__content {
		//max-width: 100%;
	}
}
