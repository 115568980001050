.outer-space {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: $color-coal;
	overflow: hidden;

  .rock {
    position: absolute;
		animation-fill-mode: both;

    &-1 {
			left: 75%;
			width: 6vw;
			height: 6vw;
      will-change: transform;
      transform: translateY(-100%);
      animation: fall1 5s linear 0s;
      animation-iteration-count: infinite;
      animation-delay: -2s;

			@include media('>=md') {
				width: 3vw;
				height: 3vw;
			}
    }

    &-2 {
			left: 50%;
			width: 5vw;
			height: 5vw;
      will-change: transform;
      transform: rotate(43deg) translateY(-150%);
      animation: fall2 5s linear;
      animation-iteration-count: infinite;

			@include media('>=md') {
				width: 3vw;
				height: 3vw;
			}
    }

    &-3 {
			left: 62%;
			width: 4vw;
			height: 4vw;
      will-change: transform;
      transform: translateY(-100%) rotate(-23deg);
      animation: fall2 5s linear 0s;
      animation-iteration-count: infinite;
      animation-delay: -3s;

			@include media('>=md') {
				width: 2vw;
				height: 2vw;
			}
    }

    &-4 {
			left: 26%;
			width: 2vw;
			height: 2vw;
      will-change: transform;
      animation: fall3 5s linear 0s;
      animation-iteration-count: infinite;
      animation-delay: -1s;
      transform: translateY(-4vh) rotate(18deg);

			@include media('>=md') {
				width: 1vw;
				height: 1vw;
			}
    }

    &-5 {
			left: 36%;
			width: 2vw;
			height: 2vw;
      will-change: transform;
      transform: translateY(-100%);
      animation: fall3 5s linear 0s;
      animation-iteration-count: infinite;
      animation-delay: -5s;

			@include media('>=md') {
				width: 1vw;
				height: 1vw;
			}
    }
  }

  .satellite {
      position: absolute;
      left: 18%;
      width: 6vw;
      height: 6vw;
      will-change: transform;
      transform: translateY(-100%);
      animation: fall4 15s linear 0s;
			animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-delay: -3s;

			@include media('>=md') {
				width: 3vw;
				height: 3vw;
			}
    }

  .rocket-container {
    position: absolute;
		top: 47%;
		left: 75%;
    will-change: transform;
    animation: pathway-mobile 10s linear;
    animation-iteration-count: infinite;

		@include media('>=md') {
			top: 50%;
			animation: pathway 10s linear;
			animation-iteration-count: infinite;
		}

    .rocket {
			display: block;
      width: 14vw;
      height: 14vw;
      will-change: transform;
      animation: driving 0.1s linear;
      animation-iteration-count: infinite;

			@include media('>=sm') {
				width: 10vw;
				height: 10vw;
			}

			@include media('>=md') {
				width: 4vw;
				height: 4vw;
			}
    }

    .rocket-flares {
			position: absolute;
			top: 95%;
			right: 0;
			left: 0;
			width: 5.6vw;
			height: 5.6vw;
			margin: auto;
      stroke-dasharray: 20;
      animation: exhaust 0.4s linear;
      animation-iteration-count: infinite;
      opacity: 0.5;

			@include media('>=md') {
				width: 1.4vw;
				height: 1.4vw;
			}
    }
  }
}


@keyframes pathway-mobile {
	0% {
		transform: translateX(0) translateY(0);
	}
	28% {
		transform: translateX(0vw) translateY(0);
	}
	31% {
		transform: translateX(-40vw) translateY(0);
	}
	41% {
		transform: translateX(-40vw) translateY(0);
	}
	44% {
		transform: translateX(0) translateY(0);
	}

	57% {
		transform: translateX(0) translateY(0);
	}

	80% {
		transform: translateX(0vw) translateY(0);
	}
	87% {
		transform: translateX(-20vw) translateY(0);
	}
	90% {
		transform: translateX(-20vw) translateY(0);
	}
	95% {
		transform: translateX(0vw) translateY(0);
	}

	100% {
			transform: translateX(0) translateY(0);
	}
}

@keyframes pathway {
		0% {
			transform: translateX(0) translateY(0);
		}
		35% {
				transform: translateX(0) translateY(0);
		}
		38% {
			transform: translateX(-5vw) translateY(1px);
		}
		45% {
			transform: translateX(-5vw) translateY(1px);
		}
		48% {
				transform: translateX(0) translateY(0);
		}
		57% {
				transform: translateX(0) translateY(0);
		}

		60% {
				transform: translateX(0) translateY(-5%);
		}
		63% {
				transform: translateX(0) translateY(0);
		}

		80% {
				transform: translateX(0) translateY(0);
		}
		87% {
				transform: translateX(0) translateY(0);
		}
		89% {
				transform: translateX(5vw) translateY(0);
		}
		95% {
				transform: translateX(5vw) translateY(0);
		}
		97% {
				transform: translateX(0) translateY(0);
		}
		100% {
				transform: translateX(0) translateY(0);
		}
	}

@keyframes exhaust {
  to {
       stroke-dashoffset: -40;
  }
}

@keyframes driving {
  to {
      transform: translateX(0.01vw) translateY(0.03vw);
  }
}

@keyframes fall1 {
  49% {
     transform: translateY(120vh) rotate(0);
  }
  49.001% {
    transform: scale(0.75) translateX(-24vw) translateY(-100%) rotate(44deg);
  }
  100% {
    transform: scale(0.75) translateX(-24vw) translateY(120vh) rotate(90deg);
  }
}

@keyframes fall2 {
  49% {
     transform: translateY(120vh) rotate(360deg);
  }
  49.001% {
    transform: scale(1.25) translateX(25vw) translateY(-100%) rotate(-360deg);
  }
  100% {
    transform: scale(1.25) translateX(25vw) translateY(120vh) rotate(0deg);
  }
}

@keyframes fall3 {
  49% {
     transform: translateY(130vh) rotate(45deg);
  }
  49.001% {
    transform: scale(0.75) translateX(18vw) translateY(-100%) rotate(-44deg);
  }
  100% {
    transform: scale(0.75) translateX(18vw) translateY(140vh) rotate(90deg);
  }
}

@keyframes fall4 {
  24% {
     transform: translateY(130vh) rotate(0deg);
  }
  24.001% {
    transform: scale(0.5) translateX(70vw) translateY(-100%) rotate(24deg);
  }
  50% {
    transform: scale(0.5) translateX(70vw) translateY(200vh) rotate(0deg);
  }
  50.001% {
    transform: translateY(-100%) rotate(0deg);
  }
}
