.page-columns {
	@include responsify($wrapper-padding, padding-left);
	@include responsify($wrapper-padding, padding-right);
	display: flex;
	flex-flow: column;
	row-gap: 4em;

	@supports not (row-gap: 6em) {
		margin-bottom: 6em;
	}

	@include media($breakpoint-desktop-up) {
		row-gap: 0;
		flex-flow: row;

		@supports not (row-gap: 6em) {
			margin-bottom: 0;
		}
	}

	&__left,
	&__right {
		@include media($breakpoint-desktop-up) {
			flex: 0 0 50%;
		}
	}

	.dark &,
	.theme--motion &,
	&.dark {
		background: $color-coal;
	}
}
