.preview {
	@include responsify($spacing-md, padding-top);
	//@include responsify($spacing-md, padding-bottom);
	display: flex;
	flex-flow: row;
	align-items: center;
	opacity: 0;
	transform: translateY(100%);
	transition: transform 0.4s cubic-bezier(0, 0.54, 0, 0.98), opacity 0.2s ease-out;
	transition-delay: 1.15s;
		
	.page--ready & {
		opacity: 1;
		transform: translateY(0);
	}

	&__text {
		@include fluidsize($font-sizes-sm);
		color: $color-grey;

		&--white {
			color: $color-white;
		}
	}
}

.dots {
	@include responsify($spacing-sm, margin-right);
	transform: translateY(-6px);
	color: $color-grey;

	&--white {
		color: $color-white;
	}

	&__dot {
		width: 12px;
		height: 12px;
		border-radius: 100%;
		background: currentColor;

		&--top {
			opacity: 0.5;
			animation: dotOne 1s $ease-in-out-cubic infinite;
			transform: translateY(8px);
		}

		&--middle {
			opacity: 0.8;
			animation: dotTwo 1s 0.1s $ease-in-out-cubic infinite;
			transform: translateY(4px);
		}

		&--bottom {
			animation: dotThree 1s 0.2s $ease-in-out-cubic infinite;
		}
	}
}

@keyframes dotOne {
	0% {opacity: 0}
	100% {opacity: 0.6}
}

@keyframes dotTwo {
	0% {opacity: 0}
	100% {opacity: 0.8}
}

@keyframes dotThree {
	0% {opacity: 0}
	100% {opacity: 1}
}
