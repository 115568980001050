.scroll-progress {
  overflow: hidden;
  pointer-events: none;;
  position: fixed;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.375rem;
  z-index: 9999999;

  &:after {
    background: $color-interactive;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(calc((100 - var(--scrolled)) * -1%), 0, 0);
    transition: 0.1s $ease-out-quad;
    width: 100%;
    height: 100%;
    will-change: transform;
  }
}

