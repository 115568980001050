.button,
button {
	@include button();

	&,
	&--primary {
		@include button-variant($color: $button-primary-color, $bg: $button-primary-bg);
		@include button-variant-hover($color: $button-primary-color-hover, $bg: $button-primary-bg-hover);
	}

	&--secondary {
		@include reset-button();
		display: flex;
		align-items: center;

		&-text,
		> span {
			@include responsify($spacing-xs, margin-right);
		}

		.icon--circle-arrow {
			$svgSize: 1.5em;
			width: $svgSize;
			height: $svgSize;
			stroke: currentColor;
			transition: 0.3s $ease-in-out-cubic;
		}

		&:hover {
			.icon--circle-arrow {
				transform: translateX(5px);
			}
		}
	}

	@include button-disabled() {

	}

	&:focus-visible {
		@include focusStyle();
	}
}
