.page-loader {
	$loader-animation-duration: 0.25s;
	animation: stripe-in $loader-animation-duration $ease-out-quad forwards;
	translate: 0 -100% 0;
	background: $page-loader-bg;
	color: $page-loader-color;
	display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
	transform-origin: right bottom;
  z-index: 9999999999;

	.page--loaded & {
		animation: stripe-out $loader-animation-duration $ease-out-quad forwards;
		transform-origin: right top;
		translate: 0 0 0;
	}

	.page--ready & {
		visibility: hidden;
	}

	html:not(.page--initialized) & {
		animation: none;
		translate: 0 0 0;
	}

	svg {
		@include responsify($font-sizes-lg);
		animation: logo-pulsate 1s $ease-in-out-quad alternate infinite;
		opacity: 1;
		transition: opacity 0.3s ($loader-animation-duration + 0.1s) $ease-out-quad;
		width: 1.75em;
		height: 1.75em;

		.page--loaded & {
			animation: none;
			opacity: 0;
			transition: 0s;
		}

		.page--ready & {
			animation: none;
			opacity: 0;
			transition: 0s;
		}
	}
}

@keyframes stripe-in {
  0% {
		transform: skew(0deg, 5deg);
    translate: 0 -100% 0;
  }
  100% {
		transform: skew(0deg, 0deg);
		translate: 0 0 0;
  }
}

@keyframes stripe-out {
  0% {
		transform: skew(0deg, 0deg);
		translate: 0 0 0;
  }
  100% {
		transform: skew(0deg, -5deg);
		translate: 0 100% 0;
  }
}

@keyframes logo-pulsate {
	0% {
		scale: 1;
	}
	100% {
		scale: 1.15;
	}
}
