@mixin bgColors($darkTxtClr: $color-white, $greyTxtClr: $color-primary, $greenTxtClr: $color-white, $redTxtClr: $color-white, $whiteTxtClr: $color-primary) {
	&--dark {
		background: $color-coal;
		color: $darkTxtClr;
	}

	&--grey {
		background: $color-grey-light;
		color: $greyTxtClr;
	}

	&--darkgrey {
		background: $color-dark-coal;
		color: $darkTxtClr;
	}

	&--green {
		background: $color-interactive;
		color: $greenTxtClr;
	}

	&--red {
		background: $color-motion;
		color: $redTxtClr;
	}

	&--white {
		background: $color-white;
		color: $whiteTxtClr;
	}

	@content
}
