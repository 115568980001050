/**
 * Spacing helpers
 */

.spacing {
  &--top-1,
  &--top-xs-1 {
    padding-top: 1rem;
  }

  &--top-2,
  &--top-xs-2 {
    padding-top: 2rem;
  }

  &--top-3,
  &--top-xs-3 {
    padding-top: 3rem;
  }

  &--top-4,
  &--top-xs-4 {
    padding-top: 4rem;
  }

  &--top-5,
  &--top-xs-5 {
    padding-top: 5rem;
  }

  &--top-6,
  &--top-xs-6 {
    padding-top: 6rem;
  }

  &--bottom-1,
  &--bottom-xs-1 {
    padding-bottom: 1rem;
  }

  &--bottom-2,
  &--bottom-xs-2 {
    padding-bottom: 2rem;
  }

  &--bottom-3,
  &--bottom-xs-3 {
    padding-bottom: 3rem;
  }

  &--bottom-4,
  &--bottom-xs-4 {
    padding-bottom: 4rem;
  }

  &--bottom-5,
  &--bottom-xs-5 {
    padding-bottom: 5rem;
  }

  &--bottom-6,
  &--bottom-xs-6 {
    padding-bottom: 6rem;
  }

  @each $breakpoint, $screensize in $breakpoints {
    @if ($breakpoint != 'xxs' and $breakpoint != 'xs') {
      @include media('>=#{$breakpoint}') {
        @for $i from 1 to 6 {
          &--top-#{$breakpoint}-#{$i} {
            padding-top: ($i * 1rem);
          }

          &--bottom-#{$breakpoint}-#{$i} {
            padding-bottom: ($i * 1rem);
          }
        }
      }
    }
  }
}

.margin-spacing {
	&--normal {
		@include responsify($spacing-lg, 'margin-top');
		@include responsify($spacing-lg, 'margin-bottom');
	}

	&--top {
		@include responsify($spacing-lg, 'margin-top');
	}

	&--bottom {
		@include responsify($spacing-lg, 'margin-bottom');
	}

	&--extended {
		@include responsify($spacing-xxxl, 'margin-top');
		@include responsify($spacing-xxxl, 'margin-bottom');

		&-top {
			@include responsify($spacing-xxxl, 'margin-top');
			@include responsify($spacing-no, 'margin-bottom');
		}

		&-bottom {
			@include responsify($spacing-no, 'margin-top');
			@include responsify($spacing-xxxl, 'margin-bottom');
		}
	}
}

.padding-spacing {
	@include responsify($spacing-no, 'margin-top');
	@include responsify($spacing-no, 'margin-bottom');

	&--normal {
		@include responsify($spacing-lg, 'padding-top');
		@include responsify($spacing-lg, 'padding-bottom');
	}

	&--top {
		@include responsify($spacing-lg, 'padding-top');
	}

	&--bottom {
		@include responsify($spacing-lg, 'padding-bottom');
	}

	&--extended {
		@include responsify($spacing-xxxl, 'padding-top');
		@include responsify($spacing-xxxl, 'padding-bottom');

		&-top {
			@include responsify($spacing-xxxl, 'padding-top');
			@include responsify($spacing-no, 'padding-bottom');
		}

		&-bottom {
			@include responsify($spacing-no, 'padding-top');
			@include responsify($spacing-xxxl, 'padding-bottom');
		}
	}
}

.extended-inner-spacing>* {
  @include responsify($spacing-lg, 'padding-top');
	@include responsify($spacing-lg, 'padding-bottom');
}
