.team-element {
	$team: &;
	@include bgColors();
	background: $color-white;
	position: relative;

	&__wrapper {
		&:not(.glide--dragging) {
			#{$team}__member {
				@include media('>=ms') {
					&:hover {
						.image-secondary {
							opacity: 1;
							transition: opacity 0.2s $ease-in-out;
						}
					}
				}
			}
		}

		.glide__arrows {
			display: none;
			position: absolute;
			@include responsify($wrapper-padding, right);
			top: 40%;
			transform: translateY(-40%);

			button {
				width: 3.5rem;
				height: 3.5rem;
				background: $color-white;
				border-radius: 100px;
			}

			@include media('>=ms') {
				display: none;
			}
		}

		&.insicht--sichtbar {
			.glide__track {
				animation: dragme 1.75s ease 3s;
				animation-fill-mode: forwards;
				transform: translateX(0px);
			}
		}
	}
	
	&__hint {
		display: flex;
		position: absolute;
		top: 0;
		bottom: 7rem;
		right: 0;
		left: 0;
		z-index: 10;
		height: 6rem;
		width: 6rem;
		margin: auto;
		justify-content: center;
		align-items: center;
		color: $color-white;
		background: $color-interactive;
		border-radius: 100%;
		transform: translateX(-30px);
		opacity: 0;
		pointer-events: none;
		@include fluidsize($font-sizes-xs);

		svg {
			width: 20px;
			height: 20px;
			path {
				stroke: white;
			}
		}

		.insicht--sichtbar & {
			animation: fadeInOut 5s ease 3s;
			animation-fill-mode: forwards;
			transform: translateX(0px);
			opacity: 0;
		}
	}

	&__list {
		display: flex;
		flex-flow: row nowrap;

	}

	&__member {
		&:hover {
			cursor: grab;

			@include media('>=ms') {
				.team-name,
				.team-title,
				.name-divider {
					color: $color-interactive;
				}
			}
		}

		&:active {
			cursor: grabbing;
		}

		&.glide__slide--active {
			@include media('<=ms') {
				.team-name,
				.team-title,
				.name-divider {
					color: $color-interactive;
				}

				.team-quote {
					opacity: 1;
					visibility: visible;
				}

				.image-primary {
					animation: fadeOut 0.2s 2s forwards linear;
				}

				.image-secondary {
					animation: fadeIn 0.2s 1.5s forwards linear;
				}
			}
		}
	}

	&__image {
		position: relative;
		aspect-ratio: 12/13;
		overflow: hidden;

		img {
			@include full-height-media();
		}

		.image-primary {
			transition: opacity 0.2s $ease-in-out;
		}

		.image-secondary {
			opacity: 0;
			transition: opacity 0.2s 0.1s $ease-in-out;
		}
	}

	&__description {
		@include responsify($spacing-md, padding);
		max-width: 100%;

		.team {
			&-name {
				@include fluidsize($font-sizes-xl);
				display: block;
				font-weight: $font-weight-bold;
				transition: color 0.3s $ease-in-out;
			}

			&-title {
				@include fluidsize($font-sizes-lg);
				display: block;
				transition: color 0.3s $ease-in-out;
			}

			&-quote {
				@include fluidsize($font-sizes-nm);
				flex: 0 0 100%;
				opacity: 0;
				visibility: hidden;
				transition: 0.3s $ease-in-out;
			}
		}

		.name-divider {
			@include fluidsize($font-sizes-sm);
			margin: 0 0.8rem;
			display: flex;
			align-items: center;
			transition: color 0.3s $ease-in-out;
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}
	100% {
		opacity: 1
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0
	}
}


@keyframes dragme {
	0% {
		transform: translateX(0px);
	}
	20% {
		transform: translateX(-20px);
	}
	40% {
		transform: translateX(0px);
	}
	60% {
		transform: translateX(0px);
	}
	80% {
		transform: translateX(-20px);
	}
	100% {
		transform: translateX(0px);
	}
}

@keyframes fadeInOut {
	0% {
		opacity: 0;
		transform: translateX(-30px);
	}
	10% {
		opacity: 1;
		transform: translateX(0px);
	}
	90% {
		opacity: 1;
		transform: translateX(0px);
	}
	100% {
		opacity: 0;
		transform: translateX(-30px);
	}
}