.contact-info {
	color: $text-color;

	@include media($breakpoint-desktop-up) {
		@include responsify($spacing-xl, padding-left);
		@include responsify($spacing-xl, padding-right);
	}

	&__content {
		div {
			@include responsify($spacing-md, margin-bottom);

			&:last-of-type {
				@include responsify($spacing-no, margin-bottom);
			}
		}
	}

	&__links {
		display: flex;
		flex-flow: row;
		column-gap: 3rem;

		a {
			@include reset-link();
			transition: transform 0.3s ease;

			&:hover {
				transform: scale(1.08);
			}

			svg {
				* {
					fill: currentColor;
				}
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;
	}

	.dark &,
	.theme--motion &{
		color: $color-white;
	}
}

.form {
	.page-columns__left & {
		max-width: 100%;
		width: 100%;

		@include media($breakpoint-desktop-up) {
			max-width: 38.5rem;
			margin-right: auto;
		}
	}
}
