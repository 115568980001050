.hero {
	$hero: &;
	position: relative;
	display: flex;
	align-items: flex-end;
	width: 100%;
	min-height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;

	&--still {
		min-height: calc(var(--vh, 1vh) * 70);;

		@include media('>=md') {
			min-height: calc(var(--vh, 1vh) * 100);;
		}
	}

	&--w-video {
		background: $color-coal;
	}

	* {
		box-sizing: border-box;
	}

	&__full-image {
		> img,
		video {
			@include full-height-media;
			transform: scale(1.05);
			opacity: 0;
			transition: transform 1s cubic-bezier(0, 0.01, 0, 1.02) 0.25s, opacity 0.5s linear 0.25s;

			.page--ready & {
				transform: scale(1);
				opacity: 1;
			}

			.hero--still & {
				opacity: 1;

				.page--ready & {
					transform: scale(1.05);
				}
			}
		}

		&--w-mask {
			//position: relative;

			> img {
				filter: blur(4px);
				transform: scale(1.05);
				animation: moveBackground 7s ease forwards infinite;
			}
		}
	}

	&__full-video {
		&.video {
			@include responsify($spacing-no, padding-left);
			@include responsify($spacing-no, padding-right);
			width: auto;
		}

		.video__wrapper {
			position: static;

			video {
				position: absolute;
				height: 100%;
				object-fit: cover;
				opacity: 0;
				transition: opacity 0.5s ease;

				.page--ready & {
					opacity: 1;
				}
			}

			.video__poster {
				@include full-height-media;

				&:before {
					//display: none;
				}
			}
		}

		&.video-iframe {
			width: 100%;
			height: 100%;
		}
	}

	&__wrapper {
		@include responsify($wrapper-padding, padding-left);
		@include responsify($wrapper-padding, padding-right);
		@include responsify($wrapper-padding, padding-bottom);
		//@include responsify($line-height-sm, line-height);
		position: relative;
		width: 100%;

		#{$hero}--w-image &,
		#{$hero}--w-video & {
			@include gradient-bg();
		}

		.play-video & {
			display: none;
		}
	}

	&__content {
		width: 100%;
		color: $hero-content-main-color;

		@include media('>=xl') {
			max-width: 85%;
		}

		#{$hero}--w-image &,
		#{$hero}--w-video & {
			position: relative;
			color: $color-white;
			z-index: 100;
		}

		.dark &,
		.theme--motion & {
			color: $color-white;
		}
	}

	&__title {
		@include fluidsize($font-sizes-xxxl);
		margin-bottom: 0.1em;
		font-weight: $font-weight-bold;

		@include media('<sm') {
			word-break: break-word;
		}
	}

	&__subtitle {
		@include fluidsize($font-sizes-nm);
		@include responsify($line-height-md, line-height);
		font-weight: $font-weight-normal;
		opacity: 0;
		transform: translateY(100%);
		transition: transform 0.4s cubic-bezier(0, 0.54, 0, 0.98) 0.75s, opacity 0.2s ease-out 0.3s;
		transition-delay: 1s;

		.page--ready & {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&__mask {
		position: absolute;
		bottom: 0;
		left: -35%;
		right: 0;
		max-width: 61vh;
		width: 100%;
		max-height: 100%;
		height: auto;
		margin: 0 auto;

		&-inner {
			transform: translate(8%, 100%);
		}

		@include media('>=md') {
			max-width: 75vh;
			left: -11vw;
		}
	}

	&__device-mask {
		position: absolute;
		top: 0;
		right: 6.8%;
		max-width: 45%;
		width: 100%;
		height: auto;
		z-index: 9999;
	}

	&__demo-image {
		position: absolute;
		top: 0.1%;
		right: 6.7%;
		max-width: 45%;
		width: 100%;
		height: auto;
		z-index: 999;
	}

	&--animate {
		#{$hero}__mask {
			animation-name: moveAround;
			animation-duration: 10s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			animation-direction: normal;
    		animation-fill-mode: forwards;
		}

		&-phone {
			#{$hero}__mask-inner {
				animation-name: blendin;
				animation-duration: 1s;
				animation-iteration-count: 1;
				animation-timing-function: ease-out;
				animation-direction: normal;
				animation-fill-mode: forwards;
			}
		}
	}
}

@keyframes moveAround {
	20% {
		transform: translate(0.5vw, 0.1vw)
	}
	40% {
		transform: translate(0.5vw, 0.5vw)
	}
	60% {
		transform: translate(-0.25vw, 0.25vw)
	}
	80% {
		transform: translate(-0.1vw, 0vw)
	}
	100% {
		transform: translate(0, 0)
	}
}

@keyframes moveBackground {
	0% {
		transform: scale(1.05) translate(0, 0)
	}
	50% {
		transform: scale(1.05) translate(0.25vw, 0.4vw)
	}
}

@keyframes blendin {
	100% {
		transform: translate(0, 0)
	}
}
