@mixin linkHover($lineColor: currentColor) {

	text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 0.075em;
	transition: $link-transition;

	&:hover {
		background-size: 0% 0.075em;
	}
}

@mixin focusStyle() {
	box-shadow: inset 0 0 0 0.125rem var(--focus-colors);
	outline: none;
}
