$effect-delay: 0.2s;

.insicht.insicht--fade-in {
  opacity: 0;
  .no-js &,
  .page--ready &.insicht--sichtbar {
    opacity: 1;
    transition: opacity 0.8s $effect-delay $ease-out-quad;
    &.insicht--fast {
      transition-duration: 0.5s;
    }
  }
}

.insicht.insicht--fade-up {
  opacity: 0;
  transform: translate3d(0, 2rem, 0);
  .no-js &,
  .page--ready &.insicht--sichtbar {
    opacity: 1;
    transform: none;
    transition: opacity 0.3s $effect-delay $ease-out-quad, transform 0.8s $effect-delay $ease-out-expo;
    &.insicht--fast {
      transition-duration: 0.3s, 0.6s;
    }
  }
}

.insicht.insicht--fade-down {
  opacity: 0;
  transform: translate3d(0, -2rem, 0);
  .no-js &,
  .page--ready &.insicht--sichtbar {
    opacity: 1;
    transform: none;
    transition: opacity 2s $effect-delay $ease-out-quad, transform 2.4s $effect-delay $ease-out-expo;
    &.insicht--fast {
      transition-duration: 1s, 1.2s;
    }
  }
}

.insicht.insicht--fade-left {
  opacity: 0;
  transform: translate3d(-2rem, 0, 0);
  .no-js &,
  .page--ready &.insicht--sichtbar {
    opacity: 1;
    transform: none;
    transition: opacity 2s $effect-delay $ease-out-quad, transform 2.4s $effect-delay $ease-out-expo;
    &.insicht--fast {
      transition-duration: 1s, 1.2s;
    }
  }
}

.insicht.insicht--fade-right {
  opacity: 0;
  transform: translate3d(2rem, 0, 0);
  .no-js &,
  .page--ready &.insicht--sichtbar {
    opacity: 1;
    transform: none;
    transition: opacity 2s $effect-delay $ease-out-quad, transform 2.4s $effect-delay $ease-out-expo;
    &.insicht--fast {
      transition-duration: 1s, 1.2s;
    }
  }
}

.insicht.insicht--scale-up {
  opacity: 0;
  transform: scale(0);
  .no-js &,
  .page--ready &.insicht--sichtbar {
    opacity: 1;
    transform: none;
    transition: opacity 0s $effect-delay, transform 2.4s $effect-delay $ease-out-expo;
    &.insicht--fast {
      transition-duration: 0s, 0.6s;
    }
  }
}

.insicht.insicht--scale-down {
  opacity: 0;
  transform: scale(1.1);
  .no-js &,
  .page--ready &.insicht--sichtbar {
    opacity: 1;
    transform: none;
    transition: opacity 0.5s $effect-delay, transform 0.8s $effect-delay $ease-out-expo;
    &.insicht--fast {
      transition-duration: 0s, 0.6s;
    }
  }
}

.insicht.insicht--scale-fade-up {
  opacity: 0;
  transform: scale(0);
  .no-js &,
  .page--ready &.insicht--sichtbar {
    opacity: 1;
    transform: none;
    transition: opacity 2s $effect-delay $ease-out-quad, transform 2.4s $effect-delay $ease-out-expo;
    &.insicht--fast {
      transition-duration: 1s, 0.6s;
    }
  }
}

.insicht.insicht--scale-fade-down {
  opacity: 0;
  transform: scale(1.1);
  .no-js &,
  .page--ready &.insicht--sichtbar {
    opacity: 1;
    transform: none;
    transition: opacity 0.5s $effect-delay $ease-out-quad, transform 0.8s $effect-delay $ease-out-expo;
    &.insicht--fast {
      transition-duration: 1s, 0.6s;
    }
  }
}
