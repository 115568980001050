// basic colors (non element related)
// define as many as you want...
// PLEASE DO NOT DELETE EXISTING COLORS HERE! THEY ARE NEEDED WITHIN THE system

// primary Guave Interactive colors
$color-interactive: #a4c92c;
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #898989;
$color-grey-nav: #cdcdcd;
$color-grey-light: #F5F5F5; // used for input backgrounds, etc.
$color-coal: #2C2C2C;
$color-dark-coal: #181818;
$color-error-red: #FF004D;
$color-motion: #EA5B54;
$color-highlight: $color-interactive;

// the rest
$color-red: #FF0000FF;
$color-grey-ultralight: #f9f9f9;
$color-main: #000000; // main highlight color of the system
$color-yellow: #00ff00;
$color-interactive-hover: #b3df25;
$color-primary: $color-coal;
$color-grey-custom: #F2F2F2;

// focus-visible, focus color
$color-focus: $color-interactive
