.video {
	&__wrapper {
		position: relative;
		height: 0;
		//aspect-ratio: 2/1;

		&,
		&--169 {
			padding-bottom: $ratio-16-9;
		}
/*
		&--1610 {
			padding-bottom: $ratio-16-10;
		}

		&--43 {
			padding-bottom: $ratio-4-3;
		}*/

		img {
			@include full-height-media();
			pointer-events: all;
		}

		iframe,
		video {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			height: 100%;
			max-width: 100%;
			width: 100%;
			margin: 0 auto;
		}

		.teaser-grid__single &,
		.teaser-grid--double & {
			margin: 0;
		}

		.teaser-grid--flex-text-left &,
		.teaser-grid--flex-text-right & {
			margin: 0;
		}

		.cinematic-ratio & {
			aspect-ratio: 2.39/1;
			height: auto;
			padding-bottom: 0;
		}
	}

	&__poster {
		@include full-height-media;
		@include reset-link();
		cursor: pointer;
		color: $color-white;

		&:hover {
			&:before {
				opacity: 0.2;
			}

			svg {
				transform: translate(-50%, -50%) scale(1.125);
			}
		}

		&:before {
			background: $color-black;
			content: '';
			opacity: 0;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 101;
			transition: 0.3s $ease-out-quad;
		}

		svg {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: 0.3s $ease-out-quad;
			width: 3em;
			height: 3em;
			z-index: 102;
			overflow: visible;
			cursor: pointer;

			@include media('>=md') {
				width: 4em;
				height: 4em;
			}
		}
	}

	.teaser-grid--double & {
		@include responsify($spacing-no, padding);
		@include responsify($spacing-no, padding);
	}

	&-container {
		position: relative;
		width: 100%;
		overflow: hidden;

		&.hero {
			display: block;
		}
	}
}

.ce_youtube,
.ce_player {
	@for $h from 1 through 6 {
		h#{$h} {
			@include container();
			text-align: center;

			@include media('>=lg') {
				@include container('wide');
			}
		}
	}
}

.video-overlay {
	position: fixed;
	inset: 0;
	z-index: 99999;
	background: $color-coal;

	&__container {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&__wrapper {
		position: relative;
		width: 100%;
		max-height: 100%;

		&,
		&--full-screen {
			aspect-ratio: 16/9;
			// aspect-ratio: 2/1;
		}

		.cinemtatic-ratio & {
			aspect-ratio: 2.39/1;
			height: auto;
			padding-bottom: 0;
		}

		iframe,
		video {
			@include full-height-media() {
				pointer-events: all;
			};
		}
	}

	&__video {
		display: flex;
		align-items: center;
		height: 100%;
	}

	&__close {
		@include reset-button();
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: $color-white;
		z-index: 99999;

		@include media('>=sm') {
			right: 3rem;
		}

		svg {
			width: 3em;
			height: 3em;
		}
	}
}

.ce_player {
	figure {
		position: relative;
		aspect-ratio: 2/1;
	}

	.video_container {
		video {
			@include full-height-media() {
				&::-webkit-media-controls {
					display: flex;
					pointer-events: all;
				}
			};
		}

		&.cinematic-ratio {
			aspect-ratio: 2.39/1;
		}
	}
}
