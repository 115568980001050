.ba-slider {
	@include responsify($wrapper-padding, padding-left);
	@include responsify($wrapper-padding, padding-right);
	position: relative;
	text-align: center;

	.beer-slider {
		width: 100%;
		padding-bottom: 62.5%;
		height: 0;

		@include media('>=lg') {
			padding-bottom: 50%;
		}

		&__image-text-container {
			position: absolute;
			inset: 0;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.5) 80%);
				z-index: 1;
			}
		}

		&__text {
			@include responsify($spacing-lg, padding);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			display: block;
			color: $color-white;
			z-index: 1;
			text-align: left;

			@include media('>=md') {
				left: 50%;
			}
		}

		&__large-text {
			@include fluidsize($font-sizes-lg);
			display: block;
			font-weight: $font-weight-bold;
			color: $color-white;
			background: none;
			line-height: 1;
		}

		&__after-text {
			text-align: right;
		}
	}

	.beer-reveal {
		border-right: solid 2px $color-white;

		.beer-slider {
			&__text {
				left: 0;
			}
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__before {
		position: relative;
		width: 100%;
	}

	&__text {
		position: absolute;
		left: 0;
		bottom: 0;
		display: flex;
		flex-flow: column;
		justify-content: flex-end;
		color: $color-white;
	}

	.beer-handle {
		width: 2.625rem;
		height: 2.625rem;
		background: $color-white;
		transform: translate(-53%, -50%);

		&:before,
		&:after {
			left: 15px;
			bottom: 0;
			width: 10px;
			height: 15px;
			background: url('#{$icons-base-path}chevron-right.svg') no-repeat center right;
			background-size: 10px 15px;
			border: none;
			transform: translateY(-50%);
		}

		&:before {
			transform: translateY(50%) rotate(180deg);
		}

		&:after {
			left: auto;
			right: 5px;
		}

		@include media('>=md') {
			width: 3.625rem;
			height: 3.625rem;

			&:before,
			&:after {
				left: 24px;
				width: 15px;
				height: 15px;
				background-size: 15px 15px;
			}

			&:after {
				left: auto;
				right: 10px;
			}
		}

		@include media('>=xl') {
			width: 4.625rem;
			height: 4.625rem;

			&:before,
			&:after {
				height: 35px;
				background-size: 15px 35px;
			}
		}
	}

	.beer-range {
		&:focus {
			~ .beer-handle {
				background: $color-white;
				box-shadow: none;
			}
		}
	}
}
