@mixin font($type: 'normal') {
	// examples
	@if ($type == 'normal') {
		font-family: $font-family-base;
		font-style: $font-style-normal;
		font-weight: $font-weight-normal;
	}
	@else if ($type == 'light') {
		font-family: $font-family-base;
		font-style: $font-style-normal;
		font-weight: $font-weight-light;
	}
	@else if ($type == 'bold') {
		font-family: $font-family-base;
		font-style: $font-style-normal;
		font-weight: $font-weight-bold;
	}
	@else if ($type == 'italic') {
		font-family: $font-family-serif;
		font-style: $font-style-italic;
		font-weight: $font-weight-italic;
		letter-spacing: 0;
		text-transform: none;
	}
}
