.primary-link {
	$link-el: &;
	@include reset-link();
	display: flex;
	align-items: center;
	overflow: visible;
	background: transparent;
	padding: 0;

	&--big {
		@include responsify($font-sizes-md);
	}

	&__text {
		@include responsify($spacing-xxs, margin-right);
		font-weight: $font-weight-bold;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		&:before {
			content: "";
			display: inline-block;
			position: absolute;
			right: 0;
			width: 1.25em;
			height: 1.25em;
			border: 0.075em solid currentColor;
			border-radius: 100%;
			transition: transform 0.2s ease;
		}

		&:after {
			content: "";
			background: currentColor;
			display: inline-block;
			mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.539' height='50.079' viewBox='0 0 25.539 47.079'%3E%3Cpath id='Path_207' data-name='Path 207' d='M-6120.749-9457.928l-20.711,20.711,20.711,20.712' transform='translate(-6117.92 -9413.677) rotate(180)' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='7'/%3E%3C/svg%3E");
			mask-repeat: no-repeat;
			mask-position: 55% center;
			mask-size: 25%;
			overflow: hidden;
			width: 1.25em;
			height: 1.25em;
			margin-left: 0.35em;
			border-radius: 100%;
			transition: transform 0.2s ease;
			will-change: mask-position;
		}
	}

	&:hover {
		#{$link-el}__text {
			&:before,
			&:after {
				transform: translateX(1px);
			}

			&:after {
				animation: arrowblend 0.3s ease;
			}
		}
	}
}
