.marquee {
	@include bgColors();
	position: relative;
	max-width: 100%;
	overflow: hidden;

	&__wrapper {
		display: flex;
		align-items: center;
	}

	&__text {
		@include responsify($spacing-lg, padding-left);
		@include responsify($spacing-lg, padding-right);
		@include fluidsize($font-sizes-marquee);
		display: inline-block;
		white-space: nowrap;
		font-weight: $font-weight-bold;
		animation: marquee 15s linear infinite;
	}
}

@keyframes marquee {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-100%, 0, 0);
	}
}
