.page-sides {
	display: flex;
	height: 100vh;
	flex-flow: column wrap;

	@include media('>=ms') {
		flex-flow: row wrap;
	}

	&__left,
	&__right {
		@include reset-link();
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
		text-align: center;
		cursor: pointer;

		&:hover {
			.main-logo {
				&__logo {
					transform: scale(1.03);

					@include media('>=md') {
						transform: scale(1.55);
					}
				}
			}
		}

		.main-logo {
			&__logo {
				height: 100%;
				margin-bottom: 0.75em;
				transform: scale(1);
				transition: transform 0.3s ease;

				@include media('>=md') {
					transform: scale(1.5);
				}
			}
		}
	}

	&__left {
		.main-logo {
			&__solo path,
			&__text-guave path {
				fill: $color-interactive;
			}
		}
	}

	&__right {
		background: $color-coal;
		color: $color-white;

		&:hover {
			color: $color-white;
		}
	}
}

.intro-box {
	width: 75%;

	@include media('>=xl') {
		width: 50%;
	}

	.primary-link {
		display: inline-block;
	}
}
