.header {
	@include responsify($spacing-md, padding-top);
	@include responsify($spacing-md, padding-bottom);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	color: $header-font-color;
	z-index: 9999;

	.page--scrolled-fullheight & {
		mix-blend-mode: difference;
		color: $color-white;
		background: transparent;

		/* fix heavy font weight contrast on windows */
		.nav-main a.nav-main__nav-item-link {
			-webkit-text-fill-color: transparent;
			background: white;
			background-clip: text;
		}
	}

	@include media($breakpoint-desktop-up) {
		@include responsify($spacing-lg, padding-top);
		@include responsify($spacing-lg, padding-bottom);
	}

	@include media('>=xxl') {
		@include responsify($spacing-xl, padding-top);
		@include responsify($spacing-xl, padding-bottom);
	}

	@include media($breakpoint-desktop-down) {
		.show--nav & {
			transform: none;
			mix-blend-mode: normal;
		}
	}

	&__content {
		@include responsify($wrapper-padding, padding-left);
		@include responsify($wrapper-padding, padding-right);
		display: flex;
		justify-content: space-between;
		align-content: center;
		align-items: center;

		.show--nav & {
			color: $color-white;
		}
	}

	.main-logo {
		@include reset-link();
		display: flex;
		align-items: center;
		width: 13.875rem;
		height: auto;
		margin-right: 1rem;
		position: relative;
		z-index: 10;
		color: $text-color;
		transform-origin: 4% center;
		transition: transform 0.25s ease;

		@include media($breakpoint-desktop-up) {
			width: 18.75rem;
		}

		.page--scrolled & {
			&:hover {
				transform: scale(1.1);
			}
		}

		&__logo {
			color: $logo-main-color;

			* {
				fill: currentColor;
			}
		}

		&__solo {
			color: $color-logo-fat-text;
			transition: transform 0.3s ease;

			.header--dark &,
			.header--red & {
				color: $color-white;
			}

			.page--scrolled-fullheight & {
				color: $color-white;
			}

			.header--transparent & {
				color: $color-white
			}
		}

		&__text {
			transition: opacity 0.3s ease;

			.page--scrolled & {
				opacity: 0;
				visibility: hidden;
			}

			&-guave {
				color: $color-logo-fat-text;

				.header--dark &,
				.header--red & {
					color: $color-white;
				}

				.header--transparent & {
					color: $color-white
				}
			}

			&-brand {
				.header--dark &,
				.header--red & {
					color: $color-white;
				}

				.header--transparent & {
					color: $color-white
				}

				* {
					fill: currentColor;
				}
			}
		}

		.show--nav & {
			z-index: -1;
			opacity: 0;
			visibility: hidden;
		}

		&-svg {
			display: block;
			fill: currentColor;
			width: 100%;
			height: auto;
		}
	}

	&__hamburger {
		@include reset-button();
		color: inherit;
		position: relative;
		padding: 0.25rem 0;
		transition: 0.2s $ease-out-quad;
		width: 2rem;
		height: 2rem;
		z-index: 10;

		@include media('>=sm') {
			display: none;
		}

		&-line {
			background: currentColor;
			display: block;
			position: absolute;
			left: 0;
			transition: 0.2s $ease-out-quad;
			width: 100%;
			height: 0.125rem;

			&:nth-child(1) {
				top: 0.25rem;
			}

			&:nth-child(2) {
				top: 50%;
				transform: translateY(-50%);
				transform-origin: right center;
			}

			&:nth-child(3) {
				bottom: 0.25rem;
			}

			.show--nav & {
				&:nth-child(1) {
					transform: translateY(-50%) rotate(-45deg);
					top: 50%;
				}

				&:nth-child(2) {
					opacity: 0;
					transform: translateY(-50%) scale(0, 1);
				}

				&:nth-child(3) {
					transform: translateY(50%) rotate(45deg);
					bottom: 50%;
				}
			}
		}
	}

	&__navigation {
		$items: 3;
		@include media('<sm') {
			background: $color-interactive;
			color: $color-white;
			opacity: 0;
			pointer-events: none;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 100%;
			left: 0;
			transition-property: opacity, visibility, bottom;
			transition-duration: 0.1s, 0s, 0s;
			transition-delay: 0s, 0.1s, 0.1s;
			visibility: hidden;

			.show--nav & {
				opacity: 1;
				pointer-events: all;
				bottom: 0;
				transition-delay: 0s, 0s, 0s;
				visibility: visible;
			}
		}

		&-list {
			@include reset-list();
			color: inherit;
			display: flex;
			text-transform: uppercase;

			@include media('<sm') {
				flex-flow: column;
				justify-content: flex-start;
				align-content: center;
				align-items: center;
				margin: 0;
				padding: 0;
				overflow: auto;
				-webkit-overflow-scrolling: touch;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			@include media('>=sm') {
				justify-content: flex-end;
				margin: 0;
			}
		}

		&-item {
			@include media('<sm') {
				opacity: 0;
				padding: 1rem;
				transform: translate3d(0, 1em, 0);
				transition: 0.2s $ease-out-quad;

				&:first-child {
					margin-top: auto;
				}

				&:last-child {
					margin-bottom: auto;
				}

				@for $i from 0 through $items {
					$ii: ($items - $i);
					&:nth-child(#{$ii + 1}) {
						transition-delay: ($i * 0.015s);
					}
				}

				.show--nav & {
					opacity: 1;
					transform: none;
					transition: 0.5s $ease-out-expo;

					@for $i from 0 through $items {
						&:nth-child(#{$i + 1}) {
							transition-delay: ($i * 0.025s);
						}
					}
				}
			}

			@include media('>=sm') {
				padding: 0 0 0 1rem;
			}
		}

		&-link {
			@include reset-link();
			position: relative;

			&:before,
			&:after {
				background: currentColor;
				content: '';
				opacity: 0.5;
				position: absolute;
				transition: 0.2s $ease-out-quad;
				width: 0;
				height: 0.125em;
			}

			&:before {
				bottom: 100%;
				left: 0;
			}

			&:after {
				top: 100%;
				right: 0;
			}

			@include hover() {
				&:hover {
					color: inherit;

					&:before {
						right: 0;
						left: auto;
						width: 100%;
					}

					&:after {
						right: auto;
						left: 0;
						width: 100%;
					}
				}
			}

			&:focus,
			&:active,
			&--active {
				&:before {
					right: 0;
					left: auto;
					width: 100%;
				}

				&:after {
					right: auto;
					left: 0;
					width: 100%;
				}
			}

			&--active {
				&:before,
				&:after {
					opacity: 1;
				}
			}
		}
	}

	.header--dark & {
		background: $color-black;
		color: $color-white;
	}

	.header--transparent & {
		background: transparent;
		color: $color-white;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 100%);
			z-index: -1;
		}

		.page--scrolled-fullheight & {
			&:before {
				display: none;
			}
		}
	}

	.header--red & {
		background: $color-motion;
		color: $color-white;
	}

	.header--white & {
		background: $color-white;
		color: $color-primary;
	}

	// page class
	.sticky--header & {
		position: sticky;
	}

	.startpage & {
		position: fixed;
	}
}
