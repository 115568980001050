.header {
	.main-logo {
		&__solo {
			color: $color-coal;

			.header--dark & {
				color: $color-coal;
			}
		}

		&__text {
			&-guave {
				color: $color-coal;

				.header--dark & {
					color: $color-coal;
				}
			}

			&-brand {
				display: none;
			}
		}
	}
}

