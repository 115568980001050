@use "sass:math";

.form {
	&-row {
		@include media('>=sm') {
			@include grid($cols: 2, $col-gap: $spacing-col-row-grid, $row-gap: $spacing-col-row-grid) {
				align-items: flex-start;
			}
		;
		}

		&__span {
			@include media('>=sm') {
				@include grid-item($col: 2);
			}
		}

		&__wrapper {
			display: block;
			width: 100%;
		}
	}

	&-group {
		@include responsify($spacing-input-group-half, 'margin-right', '-');
		@include responsify($spacing-input-group-half, 'margin-left', '-');
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-content: flex-start;
		align-items: flex-start;

		// @include grid($cols: 12, $col-gap: $spacing-input-group, $row-gap: $spacing-col-row-grid) {
		// 	align-items: flex-start;
		// 	align-items: start;
		// }

		&__span {
			flex: 1 1 auto;
			max-width: 100%;

			@for $i from 1 through 12 {
				&--#{$i} {
					@include responsify($spacing-input-group-half, 'padding-right');
					@include responsify($spacing-input-group-half, 'padding-left');
					// grid-column: span $i;
					flex: 0 0 (math.div(100%, 12) * $i);
					max-width: (math.div(100%, 12) * $i);
				}
			}

			@each $breakpoint, $screensize in $breakpoints {
				@if ($breakpoint != 'xxs' and $breakpoint != 'xs') {
					@include media('>=#{$breakpoint}') {
						@for $i from 1 through 12 {
							&--#{$breakpoint}-#{$i} {
								@include responsify($spacing-input-group-half, 'padding-right');
								@include responsify($spacing-input-group-half, 'padding-left');
								// grid-column: span $i;
								flex: 0 0 (math.div(100%, 12) * $i);
								max-width: (math.div(100%, 12) * $i);
							}
						}
					}
				}
			}
		}
	}

	&-explanation,
	&-html,
	&-message {
		@include responsify($font-sizes-sm);
		@include responsify($spacing-sm, 'margin-bottom');
	}

	&-fieldset {
		@include reset-input();
		@include responsify($spacing-sm, 'padding-top');
		@include responsify($spacing-sm, 'padding-right');
		@include responsify($spacing-sm, 'padding-left');
		@include responsify($spacing-sm, 'margin-bottom');
		border: 0.0625rem solid $field-containerbgcolor;
		display: block;

		body:not(:-moz-handler-blocked) & {
			display: table-cell;
		}

		&__legend {
			@include reset-fieldset-legend();
			@include responsify($font-sizes-sm);
			@include font('bold');
			@include responsify($spacing-sm, 'margin-bottom');
		}
	}

	&-input {
		$input: &;

		&--error {
			#{$input}__field {
				color: $field-linecolorinvalid;
				box-shadow: inset 0 -0.125rem 0 0 $field-linecolorinvalid;

				&:not(:placeholder-shown) {
					background: transparent;
				}
			}
		}

		&--icon {
			#{$input}__field,
			#{$input}__label {
				padding-right: calc(2.3em + #{$field-padding});
			}
		}

		// elements

		&__wrapper {
			@include responsify($spacing-sm, 'margin-bottom');
			position: relative;

			@include hover() {
				&:hover {
					#{$input}__field {
						background: transparent;
						box-shadow: inset 0 0 0 0.125rem $field-linecolorvalid;
					}

					#{$input}__label {
						color: $field-label-color-active;
					}
				}
			}
		}

		&__field {
			@include reset-input();
			@include responsify($field-fontsize);
			@include responsify($field-line-height, 'line-height');
			background: $field-containerbgcolor; // use var
			border: 0;
			border-radius: $field-border-radius;
			color: $field-color;
			font: inherit;
			font-weight: bold;
			line-height: inherit;
			padding: (2 * $field-padding) $field-padding $field-padding; // use var
			position: relative;
			transition: background-color 0.25s ease, box-shadow 0.25s ease;
			width: 100%;

			&:-webkit-autofill {
				color: $field-color-active;
				-webkit-text-fill-color: $field-color-active;
				-webkit-box-shadow: inset 0 -0.125rem 0 0 $field-linecolorvalid, 0 0 0px 1000rem $body-bg inset;
				transition: background-color 5000s ease-in-out 0s;
			}

			&--textarea {
				min-height: 6em;
			}

/*			#{$input}:focus-within &:not(.error),
			#{$input} &:not(.error):focus,
			#{$input} &:not(.error):not(:placeholder-shown) {
				background: transparent;
				box-shadow: inset 0 0 0 0.125rem $field-linecolorvalid;
				color: $field-color-active;
			}*/

			#{$input}:focus-within &,
			&:focus,
			&:not(:placeholder-shown) {
				+ #{$input}__label {
					font-size: 0.55em;
					padding-top: $field-padding;
					color: $field-label-color-active;
				}
			}

			#{$input} &:not(.error):focus,
			#{$input} &:not(.error):not(:-ms-input-placeholder) {
				background: transparent;
				box-shadow: inset 0 0 0 0.125rem $field-linecolorvalid;
				color: $field-color-active;
			}

/*			&:focus,
			&:not(:-ms-input-placeholder) {
				+ #{$input}__label {
					font-size: 0.75em;
					padding-top: (0.25 * $field-padding);
					color: $field-label-color-active;
				}
			}*/

			#{$input}:focus-within &:not(.error),
			#{$input} &:not(.error):focus,
			#{$input} &:not(.error):focus:placeholder-shown {
				//box-shadow: inset 0 0 0 0.125rem $field-linecolorvalid, 0 0 0.5rem 0 rgba($field-linecolorvalid, 0.5);
			}

			#{$input}:focus-within &.error,
			#{$input} &.error:focus,
			#{$input} &.error:focus:placeholder-shown {
				//box-shadow: inset 0 0 0 0.125rem $field-linecolorinvalid, 0 0 0.5rem 0 rgba($field-linecolorinvalid, 0.5);
			}

			@include placeholder(transparent !important);

			&:focus-visible {
				@include focusStyle();
			}

			&.error {
				box-shadow: inset 0 0 0 0.125rem $field-linecolorinvalid;
			}
		}

		&__icon {
			fill: currentColor;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 1em;
			width: 1.25em;
			height: 100%;
		}

		&__hint {
			@include responsify($spacing-xs, 'margin-top', '-');
		}

		&__error {
			@include responsify($spacing-xs, 'margin-top', '-');
		}

		&__success {
			@include responsify($spacing-xs, 'margin-top', '-');
		}

		// blocks
		&-label {
			@include fluidsize($field-fontsize);
			@include responsify($field-line-height, 'line-height');
			color: $field-label-color;
			cursor: text;
			font: inherit;
			font-weight: $field-font-weight;
			line-height: inherit;
			padding: (1.5 * $field-padding) $field-padding 0;
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 0;
			transition: 0.15s ease;
			// use helper mixin
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			will-change: padding-top, font-size;
			// z-index: 10;

			&--raised {
				font-size: 0.75em;
				padding-top: (0.25 * $field-padding);
				color: $field-label-color-active;
			}

			&__wrapper {
				display: flex;
				align-items: baseline;
			}

			&__text {

			}

			&__option {
				margin-left: 0.4em;
				font-size: 0.55em;
			}
		}

		&-hint {
			@include responsify($spacing-sm, 'margin-bottom');
			@include responsify($font-sizes-xs);
			@include responsify($line-height-md, 'line-height');
			color: $field-additional-color;
			display: block;
			width: 100%;
		}

		&-error {
			@include responsify($spacing-sm, 'margin-bottom');
			@include responsify($font-sizes-xs);
			@include responsify($line-height-md, 'line-height');
			@include font($field-error-font-style);
			color: $field-error;
			display: block;
		}

		&-success {
			@include responsify($spacing-sm, 'margin-bottom');
			@include responsify($font-sizes-xs);
			@include responsify($line-height-md, 'line-height');
			@include font($field-success-font-style);
			color: $field-success;
			display: block;
		}
	}

	&-select {
		$input: &;

		&--error {
			#{$input}__field {
				color: $field-linecolorinvalid;
				box-shadow: inset 0 -0.125rem 0 0 $field-linecolorinvalid;
			}
		}

		&--icon {
			#{$input}__field,
			#{$input}__label {
				padding-right: calc(2.3em + #{$field-padding});
			}
		}

		// elements

		&__wrapper {
			@include responsify($spacing-sm, 'margin-bottom');
			position: relative;

			&:hover {
				#{$input}__field {
					background: transparent;
				}

				#{$input}__label {
					color: $field-label-color-active;
				}
			}

			&:after {
				border: 0 solid $field-color-active;
				border-width: 0 0.125rem 0.125rem 0;
				content: '';
				pointer-events: none;
				position: absolute;
				top: 50%;
				right: 1em;
				width: 0.5em;
				height: 0.5em;
				transform-origin: .3125em .3125em;
				transform: translateY(-50%) rotate(45deg);
				transition: 0.2s $ease-out-quad;
			}
		}

		&__field {
			@include reset-input();
			@include responsify($field-fontsize);
			@include responsify($field-line-height, 'line-height');
			background: transparent;
			border: 0;
			border-radius: $field-border-radius;
			box-shadow: inset 0 -0.125rem 0 0 $field-linecolorvalid;
			color: $field-color-active;
			font: inherit;
			font-weight: bold;
			line-height: inherit;
			padding: (2 * $field-padding) $field-padding $field-padding; // use var
			position: relative;
			transition: background-color 0.25s ease, box-shadow 0.25s ease;
			width: 100%;

			&:-webkit-autofill {
				color: $field-color-active;
				-webkit-text-fill-color: $field-color-active;
				-webkit-box-shadow: inset 0 -0.125rem 0 0 $field-linecolorvalid, 0 0 0px 1000rem $body-bg inset;
				transition: background-color 5000s ease-in-out 0s;
			}

			+ #{$input}__label {
				font-size: 0.75em;
				padding-top: (0.25 * $field-padding);
				color: $field-label-color-active;
			}

			option {
				font-weight: normal;
			}
		}

		&__icon {
			fill: currentColor;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 1em;
			width: 1.25em;
			height: 100%;
		}

		&__hint {
			@include responsify($spacing-xs, 'margin-top', '-');
		}

		&__error {
			@include responsify($spacing-xs, 'margin-top', '-');
		}

		&__success {
			@include responsify($spacing-xs, 'margin-top', '-');
		}
	}

	&-radios,
	&-checkboxes {
		@include responsify($spacing-md, 'margin-bottom');

		&__fieldset {
			@include reset-input();
			display: block;

			body:not(:-moz-handler-blocked) & {
				display: table-cell;
			}

			&-legend {
				@include reset-fieldset-legend();
				@include responsify($font-sizes-sm);
				@include font('bold');
				@include responsify($spacing-sm, 'margin-bottom');

				&-option {
					font-weight: normal;
					font-size: 0.75em;
				}
			}
		}
	}

	// temporary mixin
	@mixin radio-checkbox($border-radius: 0) {
		@include responsify($spacing-sm, 'margin-right');
		@include responsify($font-sizes-sm);
		$input: &;
		display: inline-block;
		position: relative;

		&--block {
			display: block;
		}

		&__field {
			opacity: 0;
			pointer-events: none;
			position: absolute;

			&:checked {
				+ #{$input}__label {
					#{$input}__label-box {
						box-shadow: inset 0 0 0 0.125rem $field-linecolorvalid;

						&:after {
							opacity: 1;
							//transform: rotate(45deg);
						}
					}
				}
			}

/*			&:focus {
				+ #{$input}__label {
					#{$input}__label-box {
						box-shadow: inset 0 0 0 0.125rem $field-linecolorvalid;
						background: $field-label-focus-background;
					}
				}
			}*/

			&:focus-visible {
				+ #{$input}__label {
					#{$input}__label-box {
						@include focusStyle();
					}
				}
			}
		}

		&__label {
			$padding: 0.5em;
			$line-height: 1.2;
			$width: 1.9375em;
			$height: 1.9375em;
			cursor: pointer;
			display: inline-flex;
			align-items: flex-start;
			align-content: flex-start;
			line-height: $line-height;
			padding: $padding 0;
			position: relative;

			// TODO: add reverse style with the box on the right
			&-box {
				background-color: $field-containerbgcolor;
				border: none;
				content: '';
				cursor: pointer;
				display: block;
				flex: 0 0 auto;
				margin-right: 0.5em;
				position: relative;
				top: ((($height * 0.5) - ($line-height * 0.5 * 1em)) * -1); // padding-top + (line-height / 2)
				width: $width;
				height: $height;
				border-radius: $border-radius;
				transition: background-color 0.25s ease, box-shadow 0.25s ease;

				&:after {
					content: '';
					position: absolute;
					border-radius: $border-radius;
					inset: 50% 0 0;
					margin: 0 auto;
					transform: translateY(-50%);
					transition: transform 0.2s $ease-custom;
					background: $field-linecolorvalid;
					width: ($width * 0.45);
					height: ($height * 0.45);
					opacity: 0;
				}

				.error & {
					box-shadow: inset 0 0 0 0.125rem $field-linecolorinvalid;
				}
			}

			&:hover {
				#{$input}__label-box {
					box-shadow: inset 0 0 0 0.125rem $field-linecolorvalid;
					background: transparent;
				}
			}

			&-text {
				color: $field-label-text-color;
				font-weight: $field-font-weight;
			}
		}

		@content
	}

	&-radio {
		@include radio-checkbox(50%);
	}

	&-checkbox {
		@include radio-checkbox($field-border-radius);
	}

	&-label {
		@include responsify($font-sizes-sm);
		@include font('bold');
		@include responsify($spacing-xs, 'margin-bottom');
		display: block;

		&__option {
			font-weight: normal;
			font-size: 0.75em;
		}
	}

	&-upload {
		@include responsify($spacing-sm, 'margin-bottom');

		&__field {
			@include responsify($font-sizes-sm);

			&::-webkit-file-upload-button {
				@include button();
				@include button-variant($color: $button-secondary-color, $bg: $button-secondary-bg);
				@include button-variant-hover($color: $button-secondary-color-hover, $bg: $button-secondary-bg-hover);
				@include responsify($button-small-padding-vertical, 'padding-top');
				@include responsify($button-small-padding-vertical, 'padding-bottom');
				@include responsify($button-small-padding-horizontal, 'padding-left');
				@include responsify($button-small-padding-horizontal, 'padding-right');
				@include responsify($button-small-font-size);
				border: $button-small-border;
				border-radius: $button-small-border-radius;
				box-shadow: $button-small-box-shadow;
				margin-right: 0.5em;
			}

			&::-ms-browse {
				@include button();
				@include button-variant($color: $button-secondary-color, $bg: $button-secondary-bg);
				@include button-variant-hover($color: $button-secondary-color-hover, $bg: $button-secondary-bg-hover);
				@include responsify($button-small-padding-vertical, 'padding-top');
				@include responsify($button-small-padding-vertical, 'padding-bottom');
				@include responsify($button-small-padding-horizontal, 'padding-left');
				@include responsify($button-small-padding-horizontal, 'padding-right');
				@include responsify($button-small-font-size);
				border: $button-small-border;
				border-radius: $button-small-border-radius;
				box-shadow: $button-small-box-shadow;
				margin-right: 0.5em;
			}

			&::file-selector-button {
				@include button();
				@include button-variant($color: $button-secondary-color, $bg: $button-secondary-bg);
				@include button-variant-hover($color: $button-secondary-color-hover, $bg: $button-secondary-bg-hover);
				@include responsify($button-small-padding-vertical, 'padding-top');
				@include responsify($button-small-padding-vertical, 'padding-bottom');
				@include responsify($button-small-padding-horizontal, 'padding-left');
				@include responsify($button-small-padding-horizontal, 'padding-right');
				@include responsify($button-small-font-size);
				border: $button-small-border;
				border-radius: $button-small-border-radius;
				box-shadow: $button-small-box-shadow;
				margin-right: 0.5em;
			}
		}
	}

	&-range {
		@include responsify($spacing-sm, 'margin-bottom');

		&__field {
			$track-color: $range-input-track-color-left !default;
			$track-gradient: linear-gradient(to right, $range-input-track-color-left var(--percent, 0%), $range-input-track-color-right var(--percent, 0%));
			$thumb-color: $range-input-thumb-color !default;

			$thumb-radius: 50% !default;
			$thumb-height: 2rem !default;
			$thumb-width: 2rem !default;
			$thumb-border-width: 0 !default;
			$thumb-border-color: transparent !default;
			$thumb-scale-hover: 1.15;
			$thumb-scale-active: 1.25;
			$thumb-hover-shadow: 0 0 0 0.375rem rgba($thumb-color, 0.3);
			$thumb-focus-shadow: 0 0 0 0.5rem rgba($thumb-color, 0.3);

			$track-width: 100% !default;
			$track-height: 0.5rem !default;
			$track-border-width: 0 !default;
			$track-border-color: transparent !default;

			$track-radius: 0 !default;
			$contrast: 5% !default;

			$ie-bottom-track-color: darken($track-color, $contrast) !default;

			@mixin track {
				cursor: default;
				height: $track-height;
				transition: all .2s ease;
				width: $track-width;
			}

			@mixin thumb {
				background: $thumb-color;
				border: $thumb-border-width solid $thumb-border-color;
				border-radius: $thumb-radius;
				box-sizing: border-box;
				cursor: pointer;
				height: $thumb-height;
				width: $thumb-width;
				transition: 0.2s $ease-custom;

				@include hover() {
					&:hover {
						box-shadow: $thumb-hover-shadow;
					}
				}

				&:active {
					box-shadow: $thumb-focus-shadow;
				}
			}

			appearance: none;
			background: transparent;
			margin: (($thumb-height * $thumb-scale-hover) * 0.5) 0;
			width: 100%;

			&::-moz-focus-outer {
				border: 0;
			}

			&:focus {
				outline: 0;

				&::-webkit-slider-thumb {
					box-shadow: $thumb-hover-shadow;
				}

				&::-moz-range-thumb {
					box-shadow: $thumb-hover-shadow;
				}

				&::-ms-thumb {
					box-shadow: $thumb-hover-shadow;
				}
			}

			&::-webkit-slider-runnable-track {
				@include track;
				background: $track-color;
				background: $track-gradient;
				border: $track-border-width solid $track-border-color;
				border-radius: $track-radius;
			}

			&::-webkit-slider-thumb {
				@include thumb;
				appearance: none;
				margin-top: ((-$track-border-width * 2 + $track-height) * 0.5 - $thumb-height * 0.5);
			}

			&::-moz-range-track {
				@include track;
				background: $track-color;
				background: $track-gradient;
				border: $track-border-width solid $track-border-color;
				border-radius: $track-radius;
				height: $track-height;
			}

			&::-moz-range-thumb {
				@include thumb;
			}

			&::-ms-track {
				@include track;
				background: transparent;
				border-color: transparent;
				border-width: ($thumb-height * 0.5) 0;
				color: transparent;
			}

			&::-ms-fill-lower {
				background: $ie-bottom-track-color;
				border: $track-border-width solid $track-border-color;
				border-radius: ($track-radius * 2);
			}

			&::-ms-fill-upper {
				background: $track-color;
				background: $track-gradient;
				border: $track-border-width solid $track-border-color;
				border-radius: ($track-radius * 2);
			}

			&::-ms-tooltip {
				display: none;
				opacity: 0;
				visibility: hidden;
			}

			&::-ms-thumb {
				@include thumb;
				margin-top: $track-height * 0.25;
			}

			&:disabled {
				&::-webkit-slider-thumb,
				&::-moz-range-thumb,
				&::-ms-thumb,
				&::-webkit-slider-runnable-track,
				&::-ms-fill-lower,
				&::-ms-fill-upper {
					cursor: not-allowed;
				}
			}
		}
	}

	&-button-submit {
		@extend .button--secondary;
		@include fluidsize($font-sizes-nm);
		margin-left: auto;
		color: $button-form-submit-color;
		font-weight: $button-form-submit-weight;
	}
}
