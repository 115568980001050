@import "~@glidejs/glide/src/assets/sass/glide.core";


.carousel {
	.glide__slides {
		flex-flow: row;
		align-items: center;

		.logo-carousel & {
			justify-content: center;
		}
	}

	.glide__slide {
		position: relative;

		&.logo-carousel__logo {
			//max-width: 200px;
		}
	}

	.glide__arrows {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		display: flex;
		justify-content: space-between;
		align-content: center;
		align-items: center;
		transform: translateY(-50%);
		z-index: 99;
	}

	.glide__arrow {
		@include responsify($font-sizes-lg);
		position: absolute;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		width: 0.85em;
		height: 1.75em;
		margin-top: 0;
		margin-bottom: 0;
		padding: 0;
		appearance: none;
		cursor: pointer;
		transition: 0.3s $ease-out-quad;
		background: transparent;

		@include media('>=md') {
			width: 1.75em;
		}

		@include media('>=xl') {
			padding: 0.5em;
		}

		svg {
			position: relative;
			margin: 0;
			z-index: 999;

			path {
				stroke: $color-white;
			}
		}

		&:focus-visible {
			//box-shadow: none;
			//outline: none;
		}

		&--left {
			@include responsify($wrapper-padding, left);

			&:active {
				transform: translateX(-0.125em);

				svg {
					transform: rotate(180deg);
				}
			}

			svg {
				transform: rotate(180deg);
			}
		}

		&--right {
			@include responsify($wrapper-padding, right);

			&:active {
				transform: translateX(0.125em);

				svg {
					transform: none;
				}
			}
		}

		svg {
			display: block;
			width: 0.6em;
			transition: inherit;

			@include media('>=lg') {
				width: 1em;
			}
		}
	}

	.glide__bullets {
		display: flex;
		justify-content: center;
		gap: 0.2em;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0.625rem;
		width: 100%;
		margin: 0 auto;
		//background: linear-gradient(to right, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0) 90%);
		text-align: center;

		.logo-carousel & {
			bottom: 0;
		}
	}

	.glide__bullet {
		width: 0.7rem;
		height: 0.7rem;
		border-radius: 100%;
		background: transparent;
		border: 2px solid $color-white;
		font-size: 0;
		filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));

		@include media('>=md') {
			width: 1.0625rem;
			height: 1.0625rem;
		}

		&--active {
			background: $color-white;
		}
	}
}
