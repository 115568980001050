/**
 * Text align helpers
 */

.text {
  &--left,
  &--xs-left {
    text-align: left !important;
  }
  &--center,
  &--xs-center {
    text-align: center !important;
  }
  &--right,
  &--xs-right {
    text-align: right !important;
  }
  &--justify,
  &--xs-justify {
    text-align: justify !important;
  }

  @each $breakpoint, $screensize in $breakpoints {
    @if ($breakpoint != 'xxs' and $breakpoint != 'xs') {
      @include media('>=#{$breakpoint}') {
        @each $align in left, center, right, justify {
          &--#{$breakpoint}-#{$align} {
            text-align: $align !important;
          }
        }
      }
    }
  }
}
