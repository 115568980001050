.logo-carousel {
	@include bgColors();
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	max-width: 100%;
	//overflow: hidden;

	&__wrapper {
		@include responsify($wrapper-padding, 'padding-left');
		@include responsify($wrapper-padding, 'padding-right');
		@include responsify($spacing-xl, 'padding-bottom');
		@include responsify($spacing-md, 'padding-top');
		display: flex;
		align-items: flex-start;

		&.glide__slides {
			align-items: flex-start;
		}
	}

	&__title {
		text-align: center;
		@include responsify($spacing-xl, 'padding-top');
		@include responsify($spacing-lg, 'padding-bottom');
	}

	&__logo {
		//margin: 0 4rem;
		text-align: center;

		img {
			max-width: 170px;
			margin: 0 auto;
		}
	}

	&__text {
		@include responsify($spacing-md, margin-top);
		@include fluidsize($font-sizes-nm);
		max-width: 250px;
		margin-right: auto;
		margin-left: auto;
		text-align: center;
	}

	.carousel {
		max-width: 90%;
		margin: 0 auto;
	}

	.glide__arrow--left {
		@include responsify($carousel-arrow-position, left, '-');
		@include responsify($spacing-no, padding);
	}

	.glide__arrow--right {
		@include responsify($carousel-arrow-position, right, '-');
		@include responsify($spacing-no, padding);
	}
}
