.floating-screens {
	position: relative;
	height: 200vh;

	&__wrapper {
		@include responsify($wrapper-padding, padding);
		position: sticky;
		top: 0;
		height: 100vh;
		background: $color-grey-light;
		overflow: hidden;
	}

	&__container {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		column-gap: 2vw;
		transform: rotate(335deg) scale(1.75) translateY(-15%) translateX(-60%);
		transform-origin: 0 0;

		@include media('>=md') {
			align-items: flex-start;
			transform: rotate(335deg) scale(1) translateX(-25%);
		}
	}

	&__single {
		flex: 0 0 18%;
		max-width: 344px;
		width: 100%;
		height: 100%;
		transform-origin: 0 0;
		transition: transform 0.1s $ease-in-out;

		.image-container {
			overflow: hidden;
			margin-bottom: 2vw;
		}

		&.column__fast {
			transform-origin: top left;
		}

		&.column__slow {
			transform-origin: bottom left;
		}
	}

	&__double {
		flex: 0 0 38%;
		width: 100%;
		height: 100%;
		transform-origin: 0 0;
		transition: transform 0.1s $ease-in-out;

		@include media('>=md') {
		}

		.image-container {
			overflow: hidden;
			margin-bottom: 2vw;
		}
	}

	.image-container {
		position: relative;
		//margin: 0;
		box-shadow: 15px 20px 20px rgba(0,0,0,9%);

		img {
			display: block;
			
			border-radius: 3px;

			@include media('>=xl') {
				border-radius: 6px;
			}
		}
	}
}
