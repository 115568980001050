.showcase {
	$showcase: &;

	&__wrapper {
		display: flex;
		flex-flow: column;
		align-items: stretch;
		justify-content: center;

		@include media($breakpoint-desktop-up) {
			flex-flow: row;
		}
	}

	&__content {
		@include responsify($spacing-xxl, padding-top);
		@include responsify($spacing-xxl, padding-bottom);
		@include responsify($wrapper-padding, padding-left);
		@include responsify($wrapper-padding, padding-right);
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;

		@include media($breakpoint-desktop-up) {
			aspect-ratio: 1/1;
			flex: 0 0 50%;
			max-width: 50%;
		}

		&--grey {
			background: $color-grey-custom;
		}
	}

	&__description,
	&__details {
		display: flex;
		justify-content: center;
		flex-flow: column;
		width: 100%;
	}

	&__description {
		@include fluidsize($font-sizes-xl);
		font-weight: $font-weight-bold;
	}

	&__details {
		margin: 0 auto;
	}

	&__meta {
		@include responsify($spacing-sm, margin-bottom);
		font-weight: $font-weight-bold;
	}

	&-single {
		background: $color-white;

		&__wrapper {
			display: flex;
			flex-flow: column;
			align-items: stretch;
			justify-content: center;

			@include media($breakpoint-desktop-up) {
				flex-flow: row;
			}
		}

		&__image,
		&__content {
			flex: 0 0 100%;
			max-width: 100%;

			@include media($breakpoint-desktop-up) {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}

		&__image {
			@include reset-link();
			overflow: hidden;

			&:hover {
				figure {
					transform: scale(1.015);
				}
			}

			figure {
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				margin: 0;
				padding-bottom: 0;
				transition: transform 0.4s ease;
				will-change: transform;

				@supports (aspect-ratio: 2/1) {
					aspect-ratio: 2/1;
				}

				@include media($breakpoint-desktop-down) {
					order: 0;
				}

				@include media($breakpoint-desktop-up) {
					@supports (aspect-ratio: 1/1) {
						aspect-ratio: 1/1;
					}
				}
			}

			img {
				@include full-height-media();
			}
		}

		&__content {
			@include responsify($spacing-xxxl, padding-top);
			@include responsify($spacing-xxxl, padding-bottom);
			@include responsify($wrapper-padding, padding-left);
			@include responsify($wrapper-padding, padding-right);
			position: relative;
			align-self: center;
			height: 100%;
			margin: auto;

			@include media($breakpoint-desktop-down) {
				order: 1;
			}

			a {
				@include reset-link();
				display: block;
			}
		}

		&__title {
			@include fluidsize($font-sizes-nm);
		}

		&__specification {
			font-weight: normal;
		}

		&__subtitle {
			@include responsify($spacing-sm, margin-bottom);
			@include fluidsize($font-sizes-xxl);
			line-height: 1.2;
		}

		&__link-wrapper {
			a {
				@include reset-link();
				@include fluidsize($font-sizes-nm);
			}
		}
	}

	&__tags {
		list-style: none;
		padding: 0;

		li {
			margin-bottom: 0.2em;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&-link {
		display: flex;
		font-weight: $font-weight-bold;

		a {
			display: flex;
			flex-flow: row;
			align-items: center;
		}
	}
}

.showcases {
	background: $color-coal;

	.showcase-single {
		&:nth-child(2n + 2) {
			.showcase-single__wrapper {
				@include media($breakpoint-desktop-up) {
					flex-flow: row-reverse;
				}
			}
		}
	}
}
