.features {
	$root: &;
	position: relative;
	background-color: $color-white;
	will-change: background-color;
	transition: background-color 0.85s $ease-in-out-cubic;

	&__wrapper {
		position: sticky;
		top: 0;
		display: flex;
		flex-flow: row wrap;
		height: auto;
		gap: 0;

		@include media('>=md') {
			@include responsify($wrapper-padding, 'padding-left');
			@include responsify($wrapper-padding, 'padding-right');
			@include responsify($wrapper-padding, 'gap');
		}
	}

	&__mobile-content {
		align-self: flex-start;

		@include media('>=md') {
			display: none;
		}

		.whitetext & {
			.features__single-text,
			.features__single-title {
				color: $color-white;
			}
		}
	}

	&__single {
		&-content {
			display: flex;
			align-items: flex-start;
			flex-flow: column;
			justify-content: center;
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);

			&--desktop {
				#{$root}__single-title,
				#{$root}__single-text {
					@include media('<md') {
						display: none;
					}
				}
			}

			&.whitetext {
				.features__single-text,
				.features__single-title {
					color: $color-white;
				}
			}
		}

		&-file-content {
			align-items: center;
			width: 100%;
			transition: opacity 0.3s $ease-in-out-cubic;

			@include media('>=md') {
				display: flex;
				height: 100%;
				position: absolute;
				top: 50%;
				opacity: 0;
				transform: translateY(-50%);
				flex-flow: column;
				gap: 3em;
				background-color: transparent !important; /* override style here for dynamic mobile display */
			}

			@include media('<md') {
				@include responsify($wrapper-padding, 'padding');
			}

		}

		&-file {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			@include responsify($wrapper-padding, 'padding-top');
			@include responsify($wrapper-padding, 'padding-bottom');

			@include media('>=md') {
				aspect-ratio: 1/1;
				padding: 0;
			}

			img,
			video {
				object-fit: contain;
				max-width: 100%;
				max-height: 100%;
				box-shadow: 15px 20px 20px rgba(0, 0, 0, 9%);
				border-radius: 3px;

				@include media('>=md') {
					max-height: 70%;
					border-radius: 6px;
				}
			}

			svg {
				max-width: 70%;
			}

			&--no-box-shadow {
				img,
				video {
					box-shadow: none;
				}
			}
		}

		&-title {
			@include fluidsize($font-sizes-xxl);
			font-weight: $font-weight-bold;
			line-height: 1.1;
			margin-bottom: 0.5em;
		}

		&-text {
			@include fluidsize($font-sizes-nm);
		}

		&-left {
			display: none;
			@include media('>=md') {
				display: block;
				flex: 1;
			}
		}

		&-right {
			flex: 0 0 100%;

			@include media('>=md') {
				height: 100vh;
				height: calc(var(--vh, 1vh) * 100);
				position: sticky;
				top: 0;
				flex: 1;
			}
		}

		&--blend-in {
			opacity: 1;
		}
	}
}
