.gallery {
	&__wrapper {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		padding: 0;

		@include media('>=sm') {
			grid-template-columns: repeat(2, 1fr);
		}

		@include media('>=lg') {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&__slides {
		text-align: center;

		&--transformed {
			transition: transform 0.2s $ease-in-out;
			transform: translateY(-30px);
		}

		button {
			@include responsify($spacing-xs, margin-top);
		}

		.neighbouring-caret {
			@include responsify($spacing-xxs, margin-left);
			line-height: 0;

			svg {
				width: 20px;
				transition: 0.3s $ease-out-quad;
			}

			&--rotate {
				svg {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__item {
		list-style: none;

		&-image {
			line-height: 0;
			padding: 0;

			img	{
				width: 100%;
				aspect-ratio: 16/9;
				object-fit: cover;
				cursor: pointer;
			}

			&.carousel {
				overflow: hidden;
			}
		}
	}
}

.ce_gallery  {
	@for $h from 1 through 6 {
		h#{$h} {
			@include container('wide');
		}
	}
}

.gallery-item-open {
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.7);
		z-index: 99999;
	}
}

.gallery-image-overlay {
	@include responsify($spacing-no, padding);
	position: fixed;
	inset: 0;
	opacity: 0;
	max-width: 100%;
	transform: translate3d(0, 2rem, 0);
	transition: 0.2s $ease-out-quad;
	visibility: hidden;
	z-index: 99999;

	&__close {
		@include responsify($spacing-md, top);
		@include responsify($wrapper-padding, right);
		@include responsify($spacing-no, padding);
		position: absolute;
		width: 2em;
		height: 2em;
		cursor: pointer;
		color: $color-white;
		z-index: 99999;
		background: transparent;
		filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));

		@include media('>=sm') {
			width: 3em;
			height: 3em;
		}

		&:hover {
			color: $color-white;
		}
	}

	&__wrapper {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		.carousel {
			padding-bottom: 0;

			.glide__arrow {
				@include responsify($spacing-no, padding);
				display: flex;
				filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));

				&--right {
					@include responsify($wrapper-padding, right);
				}

				&--left {
					@include responsify($wrapper-padding, left);
				}
			}
		}
	}

	.gallery--open & {
		transition: 0.3s $ease-out-quad;
		transform: translate(0);
		visibility: visible;
		opacity: 1;
		overflow-y: auto;
	}

	.glide {
		&__slide {
			display: flex;
			justify-content: center;
			align-self: center;

			img {
				max-width: 100%;
				object-fit: contain;

				@include media('>=sm') {
					max-width: 80%;
				}

				@include media('>=xxl') {
					max-width: 60%;
				}
			}
		}
	}
}

