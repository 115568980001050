.lead-text {
	@include module-padding();
	//@include responsify($lead-text-padding, 'padding-top');
	//@include responsify($lead-text-padding, 'padding-bottom');
	@include bgColors();
	color: $color-coal;
	background: $color-white;

	&__wrapper {
		@include responsify($wrapper-padding, 'padding-left');
		@include responsify($wrapper-padding, 'padding-right');
		position: relative;
	}

	&__content {
		@include media('>=lg') {
			@include responsify($spacing-no, 'padding-right');
			max-width: 1320px;
		}
	}

	&__subtitle {
		@include responsify($spacing-sm, margin-bottom);
		@include fluidsize($font-sizes-nm);
	}

	&__main-text {
		@include responsify($spacing-lg, margin-bottom);
		@include fluidsize($font-sizes-xxl);
		line-height: 1.1;
		font-weight: $font-weight-bold;

		a {
			@include linkHover();
		}
	}

	&__redirect {
		@include fluidsize($font-sizes-nm);
		display: flex;
	}
}

@keyframes arrowblend {
	0% {
		mask-position: 55% center;
	}
	49% {
		mask-position: 140% center;
	}
	49.01% {
		mask-position: -40% center;
	}
	100%{
		mask-position: 55% center;
	}
}
