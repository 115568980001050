.numbers-grid {
	@include bgColors();

	&__wrapper {
		@include responsify($wrapper-padding, 'padding-left');
		@include responsify($wrapper-padding, 'padding-right');
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 2rem;
		//column-gap: 1rem;
		height: 100%;
		margin-top: auto;
		margin-bottom: auto;

		@include media($breakpoint-desktop-up) {
			grid-template-columns: repeat(3, 1fr);
			gap: 4rem;
		}
	}

	&__single {
		display: flex;
		flex-flow: column;
		row-gap: 0.3em;
		align-items: center
	}

	&__value {
		@include fluidsize($font-sizes-xxxl);
		@include responsify($spacing-md, margin-bottom);
		display: flex;
		align-items: flex-end;
		font-weight: $font-weight-bold;
		line-height: 0.7;



		&-wrapper {
			position: relative;
		}

		&-placeholder {
			color: transparent;
			opacity: 0;
			visibility: hidden;
		}

		&-display {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&-symbol {
			@include fluidsize($font-sizes-md);
		}
	}
}
