.sticky-link {
	$root: &;
	position: sticky;
	right: 0;
	bottom: 0;
	width: 40px;
	visibility: hidden;
	opacity: 0;
	height: 0;
	margin-left: auto;
	color: $color-dark-coal;
	z-index: 9999;
	touch-action: none;

	&__link {
		@include responsify($wrapper-padding, right);
		@include responsify($wrapper-padding, bottom);
		@include reset-link();
		position: absolute;
		display: flex;
		width: 100%;
		height: 40px;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		cursor: pointer;
		background: $color-white;
		transition: 0.3s $ease-in-out-cubic;
		transform-origin: center;

		&:hover {
			transform: scale(1.1);
		}
	}

	.page--scrolled-deep & {
		opacity: 0.7;
		visibility: visible;
		touch-action: auto;
		transition: opacity 0.3s $ease-in-out-cubic, transform 0.3s $ease-in-out-cubic;
	}

	&--to-top {
		.page--scrolled-deep & {
			opacity: 1;
		}

		#{$root}__link {
			background: $color-grey-light;
		}

		svg {
			transform: rotate(270deg);
		}
	}

	.show--nav & {
		z-index: -1;
	}

	.startpage & {
		display: none;
	}
}
