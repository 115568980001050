.headline {
	&-container {
		@include responsify($spacing-md, 'padding-top');
		@include responsify($spacing-md, 'padding-bottom');
		@include bgColors();

		&--centered {
			text-align: center;
		}
	}
}
