@use "sass:math";

.screen {
	$screen: &;
	@include bgColors();
	display: flow-root;

	&__container {
		@include container();
		@include responsify($spacing-screens, padding-top);
		@include responsify($spacing-screens, padding-bottom);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;

		@include media('>=ms') {
			flex-flow: row;
		}

		.screen--single & {
			@include module-padding();
		}
	}

	&__image-container {
		img {
			margin: 0 auto;
			box-shadow: 15px 20px 20px rgba(0, 0, 0, 9%);
			border-radius: 3px;
			max-height: 70vh;

			@include media('>=xl') {
				border-radius: 6px;
			}

			&.box-shadow-remove {
				box-shadow: none;
			}
		}

		&--mobile-size {
			img {
				margin: 0 auto;
			}
		}
	}

	&__video-container {
		display: flex;
		align-items: center;
		justify-content: center;

		@include media('<lg') {
			max-width: 40rem;
			width: 100%;
		}

		video {
			height: 100%;
			max-width: 100%;
			max-height: 70vh;
			box-shadow: 15px 20px 20px rgba(0, 0, 0, 9%);
			border-radius: 3px;

			@include media('>=xl') {
				border-radius: 6px;
			}

			&.box-shadow-remove {
				box-shadow: none;
			}
		}
	}

	&--double {
		#{$screen} {
			&__container {
				@include responsify($spacing-no, padding);
				max-width: 100%;
				justify-content: space-between;
			}

			&__left,
			&__right {
				@include bgColors();
				@include responsify($spacing-screens, padding-top);
				@include responsify($spacing-screens, padding-bottom);
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				align-self: stretch;

				@include media('>=ms') {
					flex: 0 0 50%;
				}
			}

			&__image-container {
				width: 80%;
			}

			&__video-container {
				width: 80%;
				height: 100%;
			}
		}
	}

	&--triple {
		#{$screen} {
			&__container {
				@include responsify($spacing-screens, padding-top);
				@include responsify($spacing-screens, padding-bottom);
				@include responsify($spacing-xl, grid-row-gap);
				display: grid;
				justify-items: center;
				grid-template-columns: 1fr;

				@include media('>=sm') {
					@include responsify($spacing-xl, grid-column-gap);
					@include responsify($spacing-no, grid-row-gap);
					justify-content: normal;
					grid-template-columns: repeat(3, 1fr);
				}
			}

			&__image-container {
				@include media('<lg') {
					max-width: 10rem;
					width: 100%;
				}
			}
		}
	}
}




